const datamodel = {
  variables: {
    //cytogenetics and FISH
    cytogenetics: {
      name: 'cytogenetics',
      label: 'Cytogenetic string*',
      requiredErrorMsg: 'Cytogenetic data is required',
      initialValue: "",
      TE: false
    },
    fish_performed: {
      name: 'fish_performed',
      label: 'FISH performed (with relevant result over and above the cytogenetic result)',
      initialValue: false,
      TE: false,
    },
  "FISH_CBFB-MYH11": {
    name: 'FISH_CBFB-MYH11',
    label: 'FISH CBFB-MYH11',
    initialValue: false,
    TE: false,
      fish: true
  },
  "FISH_CBFB-MYH11_tested": {
    name: 'FISH_CBFB-MYH11_tested',
    label: 'FISH CBFB-MYH11 tested',
    initialValue: false,
    TE: false,
      fish: true
  },
  "FISH_RUNX1-RUNX1T1": {
    name: 'FISH_RUNX1-RUNX1T1',
    label: 'FISH RUNX1-RUNX1T1',
    initialValue: false,
    TE: false,
      fish: true
  },
  "FISH_RUNX1-RUNX1T1_tested": {
    name: 'FISH_RUNX1-RUNX1T1_tested',
    label: 'FISH RUNX1-RUNX1T1 tested',
    initialValue: false,
    TE: false,
      fish: true
  },
  "FISH_PML-RARA": {
    name: 'FISH_PML-RARA',
    label: 'FISH PML-RARA',
    initialValue: false,
    TE: false,
      fish: true
  },
  "FISH_PML-RARA_tested": {
    name: 'FISH_PML-RARA_tested',
    label: 'FISH PML-RARA tested',
    initialValue: false,
    TE: false,
      fish: true
  },
  FISH_MLL: {
    name: 'FISH_MLL',
    label: 'FISH MLL',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_MLL_tested: {
    name: 'FISH_MLL_tested',
    label: 'FISH MLL tested',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_MECOM: {
    name: 'FISH_MECOM',
    label: 'FISH MECOM',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_MECOM_tested: {
    name: 'FISH_MECOM_tested',
    label: 'FISH MECOM tested',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_Monosomy5: {
    name: 'FISH_Monosomy5',
    label: 'FISH Monosomy5',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_Monosomy5_tested: {
    name: 'FISH_Monosomy5_tested',
    label: 'FISH Monosomy5 tested',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_Monosomy7: {
    name: 'FISH_Monosomy7',
    label: 'FISH Monosomy7',
    initialValue: false,
    TE: false,
    fish: true
  },
  FISH_Monosomy7_tested: {
    name: 'FISH_Monosomy7_tested',
    label: 'FISH Monosomy7 tested',
    initialValue: false,
    TE: false,
    fish: true
  },

  //TE variables
  "History_of_MDS":{
    name: "History_of_MDS",
    label: "History of MDS",
    initialValue: false,
    TE: true,
    section: "History"
  },
  "Prior_therapy":{
    name: "Prior_therapy",
    label: "Prior therapy for another malignancy",
    initialValue: false,
    TE: true,
    section: "History"
  },
  "History_of_CMML":{
    name: "History_of_CMML",
    label: "History of CMML",
    initialValue: false,
    TE: true,
    section: "History"
  },
  "History_of_MPN":{
    name: "History_of_MPN",
    label: "History of MPN",
    initialValue: false,
    TE: true,
    section: "History"
  },
  "NPM1_mut":{
    name: "NPM1_mut",
    label: "NPM1 mut",
    initialValue: false,
    TE: true,
    section: "Mutations"
  },
  "FLT3-ITD_mut":{
    name: "FLT3-ITD_mut",
    label: "FLT3-ITD mut",
    initialValue: false,
    TE: true,
    section: "Mutations"
  },
  "FLT3-TKD_mut":{
    name: "FLT3-TKD_mut",
    label: "FLT3-TKD mut",
    initialValue: false,
    TE: true,
    section: "Mutations"
  },
  "CEBPA_biallelic_mut":{
    name: "CEBPA_biallelic_mut",
    label: "CEBPA biallelic mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "CEBPA_bZIP_mut":{
    name: "CEBPA_bZIP_mut",
    label: "CEBPA bZIP mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "TP53_mut":{
    name: "TP53_mut",
    label: "TP53 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "inv(16)(p13.1q22)":{
    name: "inv(16)(p13.1q22)",
    label: "inv(16)(p13.1q22)",
    initialValue: false,
    TE: true,
    section: "Favourable risk changes"
  },
  "t(16;16)(p13.1;q22)":{
    name: "t(16;16)(p13.1;q22)",
    label: "t(16;16)(p13.1;q22)",
    initialValue: false,
    TE: true,
    section: "Favourable risk changes"
  },
  "t(8;21)(q22;q22.1)":{
    name: "t(8;21)(q22;q22.1)",
    label: "t(8;21)(q22;q22.1)",
    initialValue: false,
    TE: true,
    section: "Favourable risk changes"
  },
  "t(9;11)(p21.3;q23.3)":{
    name: "t(9;11)(p21.3;q23.3)",
    label: "t(9;11)(p21.3;q23.3)",
    initialValue: false,
    TE: true,
    section: "Intermediate risk"
  },
  "inv(3)(q21.3q26.2)":{
    name: "inv(3)(q21.3q26.2)",
    label: "inv(3)(q21.3q26.2)",
    initialValue: false,
    TE: true,
    section: "Adverse risk"
  },
  "t(3;3)(q21.3;q26.2)":{
    name: "t(3;3)(q21.3;q26.2)",
    label: "t(3;3)(q21.3;q26.2)",
    initialValue: false,
    TE: true,
    section: "Adverse risk"
  },
  "t(v;11q23.3)":{
    name: "t(v;11q23.3)",
    label: "t(v;11q23.3)",
    initialValue: false,
    TE: true,
    section: "Aggregated cytogenetic changes"
  },
  "Monosomy":{
    name: "Monosomy",
    label: "Monosomy",
    initialValue: undefined,
    type: "num",
    TE: true,
    section: "Number of cytogenetic changes"
  },
  "Number_of_cytogenetic_changes":{
    name: "Number_of_cytogenetic_changes",
    label: "Number of cytogenetic changes",
    initialValue: undefined,
    type: "num",
    TE: true,
    section: "Number of cytogenetic changes"
  },
  "Structural":{
    name: "Structural",
    label: "Structural",
    initialValue: undefined,
    type: "num",
    TE: true,
    section: "Number of cytogenetic changes"
  },
  "Trisomy":{
    name: "Trisomy",
    label: "Trisomy",
    initialValue: undefined,
    type: "num",
    TE: true,
    section: "Number of cytogenetic changes"
  },
  "del(12p)":{
    name: "del(12p)",
    label: "del(12p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(13q)":{
    name: "del(13q)",
    label: "del(13q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(5q)":{
    name: "del(5q)",
    label: "del(5q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(7q)":{
    name: "del(7q)",
    label: "del(7q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "Monosomy17":{
    name: "Monosomy17",
    label: "Monosomy17",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "Monosomy5":{
    name: "Monosomy5",
    label: "Monosomy5",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "Monosomy7":{
    name: "Monosomy7",
    label: "Monosomy7",
    initialValue: false,
    TE: true,
    section: "Adverse risk"
  },
  "Trisomy8":{
    name: "Trisomy8",
    label: "Trisomy8",
    initialValue: false,
    TE: true,
    section: "Intermediate risk"
  },
  "add(17p)":{
    name: "add(17p)",
    label: "add(17p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "add(5q)":{
    name: "add(5q)",
    label: "add(5q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "add(7q)":{
    name: "add(7q)",
    label: "add(7q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(17p)":{
    name: "del(17p)",
    label: "del(17p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(20q)":{
    name: "del(20q)",
    label: "del(20q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "abnormal(17p)":{
    name: "abnormal(17p)",
    label: "abnormal(17p)",
    initialValue: false,
    TE: true,
    section: "Aggregated cytogenetic changes"
  },
  "Blasts":{
    name: "Blasts",
    label: "Blasts Percentage",
    initialValue: undefined,
    type: "num",
    TE: true,
    section: "Morphology"
  },
  "Multilineage_dysplasia":{
    name: "Multilineage_dysplasia",
    label: "Multilineage dysplasia",
    initialValue: false,
    TE: true,
    section: "Morphology"
  },
  "RUNX1_mut":{
    name: "RUNX1_mut",
    label: "RUNX1 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "ASXL1_mut":{
    name: "ASXL1_mut",
    label: "ASXL1 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "EZH2_mut":{
    name: "EZH2_mut",
    label: "EZH2 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "SF3B1_mut":{
    name: "SF3B1_mut",
    label: "SF3B1 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "SRSF2_mut":{
    name: "SRSF2_mut",
    label: "SRSF2 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "STAG2_mut":{
    name: "STAG2_mut",
    label: "STAG2 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "U2AF1_mut":{
    name: "U2AF1_mut",
    label: "U2AF1 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "ZRSR2_mut":{
    name: "ZRSR2_mut",
    label: "ZRSR2 mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "BCOR_mut":{
    name: "BCOR_mut",
    label: "BCOR mut",
    initialValue: false,
    TE: true,
    section: "Panel mutations"
  },
  "Acute erythroid leukaemia morphology":{
    name: "Acute erythroid leukaemia morphology",
    label: "Acute erythroid leukaemia morphology",
    initialValue: false,
    TE: true,
    section: "Morphology"
  },
  "Monosomy13":{
    name: "Monosomy13",
    label: "Monosomy13",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "Other rare genetic alteration":{
    name: "Other rare genetic alteration",
    label: "Other rare recurrent genetic alteration not listed",
    initialValue: false,
    TE: true,
    section: "Other rare recurrent genetic alteration not listed"
  },
  "add(12p)":{
    name: "add(12p)",
    label: "add(12p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(11q)":{
    name: "del(11q)",
    label: "del(11q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "del(17)(q21.2q21.2)":{
    name: "del(17)(q21.2q21.2)",
    label: "del(17)(q21.2q21.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "der(12)t(v;12p)":{
    name: "der(12)t(v;12p)",
    label: "der(12)t(v;12p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "der(17)t(v;17p)":{
    name: "der(17)t(v;17p)",
    label: "der(17)t(v;17p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "der(5)t(v;5q)":{
    name: "der(5)t(v;5q)",
    label: "der(5)t(v;5q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "der(7)t(v;7q)":{
    name: "der(7)t(v;7q)",
    label: "der(7)t(v;7q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "i(17q)":{
    name: "i(17q)",
    label: "i(17q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "idic(X)(q13)":{
    name: "idic(X)(q13)",
    label: "idic(X)(q13)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "inv(16)(p13.3q24.3)":{
    name: "inv(16)(p13.3q24.3)",
    label: "inv(16)(p13.3q24.3)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(10;11)(p12.3;q14.2)":{
    name: "t(10;11)(p12.3;q14.2)",
    label: "t(10;11)(p12.3;q14.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(11;12)(p15.4;p13.3)":{
    name: "t(11;12)(p15.4;p13.3)",
    label: "t(11;12)(p15.4;p13.3)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(11;16)(q23.3;p13.3)":{
    name: "t(11;16)(q23.3;p13.3)",
    label: "t(11;16)(q23.3;p13.3)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(12p)":{
    name: "t(12p)",
    label: "t(12p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(15;17)(q24.1;q21.2)":{
    name: "t(15;17)(q24.1;q21.2)",
    label: "t(15;17)(q24.1;q21.2)",
    initialValue: false,
    TE: true,
    section: "Favourable risk changes"
  },
  "t(16;21)(p11.2;q22.2)":{
    name: "t(16;21)(p11.2;q22.2)",
    label: "t(16;21)(p11.2;q22.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(16;21)(q24.3;q22.1)":{
    name: "t(16;21)(q24.3;q22.1)",
    label: "t(16;21)(q24.3;q22.1)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(17p)":{
    name: "t(17p)",
    label: "t(17p)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(1;22)(p13.3;q13.1)":{
    name: "t(1;22)(p13.3;q13.1)",
    label: "t(1;22)(p13.3;q13.1)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(1;3)(p36.3;q21.3)":{
    name: "t(1;3)(p36.3;q21.3)",
    label: "t(1;3)(p36.3;q21.3)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(2;11)(p21;q23.3)":{
    name: "t(2;11)(p21;q23.3)",
    label: "t(2;11)(p21;q23.3)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(3;21)(q26.2;q22.1)":{
    name: "t(3;21)(q26.2;q22.1)",
    label: "t(3;21)(q26.2;q22.1)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(3;5)(q25.3;q35.1)":{
    name: "t(3;5)(q25.3;q35.1)",
    label: "t(3;5)(q25.3;q35.1)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(3q26.2;v)":{
    name: "t(3q26.2;v)",
    label: "t(3q26.2;v)",
    initialValue: false,
    TE: true,
    section: "Aggregated cytogenetic changes"
  },
  "t(5;10)(q32;q21)":{
    name: "t(5;10)(q32;q21)",
    label: "t(5;10)(q32;q21)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(5;11)(q35.2;p15.4)":{
    name: "t(5;11)(q35.2;p15.4)",
    label: "t(5;11)(q35.2;p15.4)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(5;12)(q32;p13.2)":{
    name: "t(5;12)(q32;p13.2)",
    label: "t(5;12)(q32;p13.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(5;17)(q32;p13.2)":{
    name: "t(5;17)(q32;p13.2)",
    label: "t(5;17)(q32;p13.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(5;7)(q32;q11.2)":{
    name: "t(5;7)(q32;q11.2)",
    label: "t(5;7)(q32;q11.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(5q)":{
    name: "t(5q)",
    label: "t(5q)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(6;9)(p22.3;q34.1)":{
    name: "t(6;9)(p22.3;q34.1)",
    label: "t(6;9)(p22.3;q34.1) / (p23;q34.1)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(7;12)(q36.3;p13.2)":{
    name: "t(7;12)(q36.3;p13.2)",
    label: "t(7;12)(q36.3;p13.2)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(8;16)(p11.2;p13.3)":{
    name: "t(8;16)(p11.2;p13.3)",
    label: "t(8;16)(p11.2;p13.3)",
    initialValue: false,
    TE: true,
    section: "Other cytogenetic changes"
  },
  "t(9;22)(q34.1;q11.2)":{
    name: "t(9;22)(q34.1;q11.2)",
    label: "t(9;22)(q34.1;q11.2)",
    initialValue: false,
    TE: true,
    section: "Adverse risk"
  },
  "t(v;11p15.4)":{
    name: "t(v;11p15.4)",
    label: "t(v;11p15.4)",
    initialValue: false,
    TE: true,
    section: "Aggregated cytogenetic changes"
  },
  "t(v;17q21.2)":{
    name: "t(v;17q21.2)",
    label: "t(v;17q21.2)",
    initialValue: false,
    TE: true,
    section: "Aggregated cytogenetic changes"
  },

  //APP internals
  "_cytogenetics_message": {
    initialValue: [],
    TE: false
  },
  "_cytogenetics_error'": {
    initialValue: false,
    TE: false
  },
  "_fish_message": {
    initialValue: [],
    TE: false
  },
  "_fish_error'": {
    initialValue: false,
    TE: false
  },
  "_card_position": {
    initialValue: 0,
    TE: false
  },
  "_TE_status": { //this is the result from TE server
    initialValue: {},
    TE: false
  },
  "_TE_error": {
    initialValue: false,
    TE: false
  },
  "_TE_message": {
    initialValue: [],
    TE: false
  },
  "ack": {
    initialValue: false,
    TE: false
  },
  "age": {
    initialValue: true,
    TE: false
  },
  "cyto_preview":{
    initialValue: false,
    TE: false
  },
  "_warnings":{
    initialValue: '',
    TE: false
  }


  },
  cards: {
    other_data: {
      title: "Other variables",
      message: "Additional data for the model",
      variables: ['ASXL1_mut',
      'Acute erythroid leukaemia morphology',
      'BCOR_mut',
      'Blasts',
      'CEBPA_bZIP_mut',
      'CEBPA_biallelic_mut',
      'EZH2_mut',
      'FLT3-ITD_mut',
      'FLT3-TKD_mut',
      'History_of_CMML',
      'History_of_MDS',
      'History_of_MPN',
      'Multilineage_dysplasia',
      'NPM1_mut',
      'Prior_therapy',
      'RUNX1_mut',
      'SF3B1_mut',
      'SRSF2_mut',
      'STAG2_mut',
      'TP53_mut',
      'U2AF1_mut',
      'ZRSR2_mut'],
      sections: ['History', 'Morphology', 'Mutations', 'Panel mutations']
    },
    review: {
      title: "Review",
      message: "This data was derived from your answers. Please review and submit for analysis.",
      variables: [], //generated later
      sections: ['History', 'Morphology', 'Mutations', 'Panel mutations',
      'Adverse risk',
      'Aggregated cytogenetic changes',
      'Favourable risk changes',
      'Intermediate risk',
      'Number of cytogenetic changes',
      'Other cytogenetic changes',
      'Other rare recurrent genetic alteration not listed']
    }
  },
  card_sequence: ["cytogenetics",
    "fish_yn",
    "other_data",
    //"flt3",
    "review",
    "results",
    "results2"
  ],
  card_sequence_length: 0 //will be set
  // "cytogenetics", "fish_yn", "other_data"
};

const TE_variables = Object.keys(datamodel.variables).filter((el) => {return datamodel.variables[el].TE})

datamodel.cards.review.variables = TE_variables
datamodel.card_sequence_length = datamodel.card_sequence.length

export default datamodel