import React, { useState } from 'react';

function get_section(age, FLT3_TKD, ELN2022_TP53, DA_GO, CPX, Midostaurin) {
	let Preferred_Treatment_Option = ""
	let Strength_of_consensus = ""
	let Comment = ""
	let Alternative_treatment = ""
	let Text = ""
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go as the concurrent FLT3 variant does not alter the favourable risk conferred by the core binding factor t(8;21) re-arrangement.  The consensus for DA+Go is based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(8;21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-LOW\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS: AML 8_21\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: t 8 21\n\nDrug choice: DA+GO, DA+Mido, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "In the absence of a DA+GO option (which some experts stated as the preference), there is strong consensus for DA + midostaurin, based on the presence of FLT3-ITD.  CPX is not considered a good evidence-based option  despite the diagnosis of therapy-related AML, since patients known to have favourable risk cytogenetic abnormalities in therapy-related AML were excluded from the randomised trial comparing CPX with DA.  The consensus for DA+ midostaurin is influenced by the survival benefit observed with the addition of midostaurin to DA in the RATIFY study of patients between 18-59 years of age, and in the phase 2 AML SG 60-10 study that included older patients.  However, patients with therapy-related AML were excluded from RATIFY and those with favourable cytogenetics were ineligible for the AML SG 60-10 study weakening the evidence-base for this consensus."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n|Prior therapy for another malignancy \n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(8;21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages) \n|__________ RESULTS _____________\n| DIAGNOSIS: therapy-related AML\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: t 8 21 \n\nDrug choice: DA+Mido, CPX-351, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Go in AML with a core binding factor t(8;21) re-arrangement that confers favourable risk.  The consensus for DA+Go is based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(8;21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS: AML 8_21\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: t 8 21\n\nDrug choice: DA+GO, DA "
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Go to be the treatment of choice as favourable cytogenetic changes based on Swedish registry data (even though technically not funded on NHS).  Alfa 0701 trial was exclusively designed for de novo AML and the CPX study excluded patients with known CBF AML. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n| Prior cytotoxic therapy for non-haem malignancy \n|__________ CYTOGENETIC _________\n| inv(16)\n| t(1;10)\n| t(1;4)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: t-AML\n| DIAGNOSIS ICC: AML with inv(16), therapy related\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX or DA + GO are considered as options, due to superior outcomes compared to DA in respective clinical trials.  CPX is licensed and approved therapy for patients with AML with myelodysplasia-related cytogenetic abnormality [del(12p) in this case], but the concurrent presence of the NPM-1 mutation (commonly associated with normal karyotype AML and not a well-represented genotype in the CPX study) has made some experts prefer DA+GO, irrespective of patient age."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n|New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * del(12p)\n|__________ NPM1 ________________\n| MUTATED \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS: AML MRC\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: NPM1mut FLT3-ITDneg\n\nDrug choice: DA+GO, CPX-351, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go based on the licensing Alfa 0701 trial and meta-analysis of RCTs demonstrating survival benefit with the inclusion of GO in induction therapy in AML with non-adverse karyotype.  The AML SG 09-09 study provides additional support for the inclusion of GO in cytarabine + anthracycline combination therapy to optimise outcomes in NPM-1 mutant AML, particularly in younger patients.  Multi-lineage dysplasia is non-contributory to prognosis in AML with an NPM-1 mutation and should not be used to inform treatment.  "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| Mutated NPM1 \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML with mutated NPM1\n| DIAGNOSIS ICC: AML with mutated NPM1\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable\n\nDrug Choices: DA+GO or DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment.  Based on the superior survival of patients treated with CPX compared to DA in a phase 3 RCT, CPX is licensed and has regulatory approval for use in AML patients with antecedent MDS or CMML.  However patients in the trial were older and there was low representation of patients with NPM1 mutation in the trial, reflected in the lack of consensus regarding optimal treatment in younger patients.  "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n|Known MDS or CMML \n|__________ CYTOGENETIC _________\n| 0 cytogenetic changes:\n|Normal cytogenetics\n|__________ NPM1 ________________\n| MUTATED \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages) \n|__________ RESULTS _____________\n| DIAGNOSIS: AML MRC\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: NPM1 pos FLT3 ITD neg\n\nDrug choices: CPX-351, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Moderate"
		Comment = "There is moderate consensus regarding the use of DA+GO in the younger patient.  The consensus is based on the based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner. CPX did demonstrate a survival benefit in this group of patients with AML MRC compared to DA but the trial was limited to older patients, weakening the support for its use. No head to head data exists comparing the efficacy of DA+GO to CPX in this scenario of cytogenetically defined AML MRC without adverse karyotype. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(1;3)(p36;q21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)  \n|__________ RESULTS _____________\n| DIAGNOSIS: AML MRC\n| PROGNOSTIC GROUP 1: INTERMEDIATE\n| PROGNOSTIC GROUP 2: Intermediate risk cytogenetic changes\n\nDrug choices: DA+GO, CPX-351, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA Midostaurin use. Ratify trial showed benefit of adding Midostaurin to DA irrespective of the FLT3 allele burden or NPM1 co-mutation status. AML 19 trial results in abstract form indicate the benefit of FLAG-Ida + GO over DA + GO but no head to head comparison with DA+Mido and FLAG-Ida + GO is not licensed."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-LOW\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML_NOS\n| DIAGNOSIS ICC: AML_NOS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug choices: DA+GO, DA+Mido, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consenus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX and DA + Midostaurin are considered as options without clear consensus. DA alone is not recommended. The absence of consensus reflects the absence of data directly comparing CPX with DA + midostaurin in this clinicopathological setting. The CPX trial was designed for patients over 60 years and the drug is licensed, with regulatory approval in the United Kingdom, for use in AML patients with an antecedent myelodysplastic syndrome.  The Ratify trial was designed for patients under 60yrs and patients with secondary AML were eligible for participation. Real world evidence supports the effectiveness of both therapies in patients over 18yrs of age."
		Alternative_treatment = "Moderate consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| Prior MDS\n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-LOW\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages) \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML NOS progressing from MDS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug choices: DA+Mido, CPX-351, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go based on the licensing Alfa 0701 trial and meta-analysis of RCTs demonstrating survival benefit with the inclusion of GO to DA in induction therapy in AML with non-adverse karyotype."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML NOS\n| DIAGNOSIS ICC: AML NOS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug Choices: DA+GO or DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "CPX"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for CPX. The CPX trial was designed for patients over the age of 60yrs, and showed a survival benefit over DA in AML patients with an antecedent myelodysplastic syndrome.  However, real world evidence supports its use in younger patients and the license for use of the drug in this setting is age agnostic.  There are limited data to demonstrate the benefits of DA+Midostaurin in patients without a FLT3 variant or with DA+GO over CPX in this setting."
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| Prior MDS \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML NOS progressing from MDS\n| 2017 ELN: Intermediate risk\n| 2022 ELN: Intermediate risk\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment Either DA+Midostaurin (based on the presence of a FLT3 variant) or CPX (based on the presence of the cytogenetic abnormality that the WHO classifies as AML with myelodysplasia-related cytogenetic abnormality) are considered reasonable treatment options.  NCRI AML 19 trial has shown benefit of Flag Ida Go in NPM1 mutated subgroup but these data are currently not published as a full manuscript and the drug combination is not licensed. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * del(12p)\n|__________ NPM1 ________________\n| MUTATED \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with mutated NPM1\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug choices: DA+GO, DA+Mido, CPX-351, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX and DA + Midostaurin are considered as options, in the absence of suitably powered studies that directly compare DA+ midostaurin and CPX in similar patients.  DA or DA + GO are not recommended in this setting due to the existence of licensed and approved therapy (CPX) for patients with AML with myelodysplasia-related cytogenetic abnormality (complex karyotype in this case), based on RCT data demonstrating survival benefit with CPX compared to DA.  Although this trial recruited patients over 60 years of age, real world data that includes younger patients, and the CPX arm of the AML19 trial in patients with adverse cytogenetics demonstrate similar outcomes in younger patients.  Although a DA+GO vs CPX comparison is lacking in younger patients, previous studies have demonstrated the lack of benefit with DA + GO compared to DA in patients with adverse cytogenetic risk.  It would therefore be appropriate to extend the results of the CPX vs DA trial to younger patients.  The Ratify and AMLSG 60-10 studies indicate the superiority of DA + midostaurin over DA in younger and older patients with FLT3 mutant AML, making this the preferred option for some experts who view the perceive the benefits of targeting FLT3 as superseding those conferred by CPX in patients with complex karyotype.  Although numbers of patients with a similar genotype were small in the CPX and RATIFY studies, sub-group analysis suggests the benefits of both CPX and DA+ midostaurin over DA in the respective studies, justifying the use of either drug/combination in this particular patient."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 3 cytogenetic changes:\n| * isochromosome(17q)\n| * del(4p)\n| * del(8q)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with MR cytogenetic abnormality\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug choices: CPX-351, DA+Mido, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "CPX"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for CPX use. The CPX trial demonstrated the benefits of CPX over DA in secondary AML including AML with myelodysplasia-related cytogenetic abnormalities.  Although designed for patients over 60 years, real world evidence suggests similar improvement in outcomes in younger patients compared to other therapies.  The benefits of the drug can therefore be extended to younger patients and the drug license covers all age groups for secondary AML.  There are no data to support the benefits of DA + midostaurin or DA+ GO in this patient."
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Monosomy 7\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with myelodysplasia related cytogenetic abnormality\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "DA is the only funded treatment for this scenario (apart from FLAG-Ida – see notes below). Neither DA+GO or CPX are funded on the NHS for this scenario. The addition of GO to DA is not beneficial in adult patients with most adverse risk genetics which may have led to some experts favouring DA alone; others may have been influenced by the possible benefits of GO on event-free survival reported in children with KMT2A re-arranged disease.   CPX has been used in adverse risk patients in AML19 and shown similar efficacy to FLAG-IDA."
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| t(10;11)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML KMT2A-rearranged\n| DIAGNOSIS ICC: AML KMT2A-rearranaged\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: DA only"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "In the absence of a direct comparison of CPX and DA + Midostaurin in patients with concurrent MDS-related cytogenetic abnormalities and FLT3 variants, CPX or DA + midostaurin remain options, based on the improved survival observed in trials of the drugs compared to DA, mainly in patients without this patient’s genetic profile.  The experts acknowledge the dismal prognosis in patients with this particular combination of genomic abnormalities with currently licensed or conventional therapies including CPX and FLAG-Ida (AML-19 data) and have recommended that this patient be considered for treatment in the context of a clinical trial. "
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 3 cytogenetic changes:\n| * isochromosome(17q)\n| * del(4p)\n| * del(8q)\n|__________ TP53 ________________\n| Mutated TP53. VAF>10% \n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|__________ FLT3-TKD ____________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with mutated TP53\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug choices: CPX-351, DA+Mido, DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "FLAG-Ida"
		Strength_of_consensus = "Moderate"
		Comment = "There is moderate consensus that FLAG-Ida is preferred over licensed and approved intensive therapies. However, the experts also acknowledge the very poor prognosis with this patient’s disease genotype and recommend enrolment of the patient in a suitable clinical trial. Non-intensive treatments or palliation could also be considered."
		Alternative_treatment = " There is moderate consensus for the use of CPX where FLAG-ida not appropriate or clinical trial not available. based the randomised clinical trial indicating improved survival with CPX compared to DA in unselected patients over the age of 60 years with AML and myelodysplasia-related cytogenetic abnormalities, and real world data that extend these observations to younger patients. However, the experts also acknowledge the futility of licensed and approved therapies with the patient’s disease genotype and recommend enrolment of the patient in a suitable clinical trial. "
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Monosomy 7\n| Del(10p)\n| Del(8q)\n|__________ TP53 ________________\n| Mutated TP53. VAF>10% \n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with mutated TP53\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go as the concurrent FLT3 variant does not alter the favourable risk conferred by the core binding factor t(8;21) re-arrangement.  The consensus for DA+Go is based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go as concurrent FLT3 variant or additional chromosomal abnormalities do not alter the favourable risk conferred by the presence of a CBF re-arrangement.  The Alfa 0701 trial was exclusively designed for denovo AML and the CPX study did not recruit patients with known CBF re-arrangements."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n| Prior cytotoxic therapy for non-haem malignancy \n|__________ CYTOGENETIC _________\n| inv(16)\n| t(1;10)\n| t(1;4)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: t-AML\n| DIAGNOSIS ICC: AML with inv(16), therapy-related\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable\n\nDrug Choices: CPX-351, DA+Midostaurin or DA"
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA Midostaurin.  Ratify trial showed the survival benefit of adding Midostaurin to both ITD and TKD mutated patients irrespective of the NPM-1 co-mutational status.  Preliminary results of the AML 19 trial have demonstrated the feasibility and safety of adding Midostaurin to DA+GO, but publication of the full report is awaited and this combination is not licensed or funded as standard of care treatment."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| Mutated NPM1 \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML with mutated NPM1\n| DIAGNOSIS ICC: AML with mutated NPM1\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable \n\nDrug Choices: DA+GO, DA+Midostaurin or DA"
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX and DA + Midostaurin are considered as options without clear consensus. DA alone is not recommended. The absence of consensus reflects the absence of data directly comparing CPX with DA + midostaurin in this clinicopathological setting. The CPX trial was designed for patients over 60 years and the drug is licensed, with regulatory approval in the United Kingdom, for use in AML patients with an antecedent myelodysplastic syndrome.  The Ratify trial was designed for patients under 60yrs and patients with secondary AML were eligible for participation. Real world evidence supports the effectiveness of both therapies in patients over 18yrs of age."
		Alternative_treatment = "Moderate consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment Either DA+Midostaurin (based on the presence of a FLT3 variant) or CPX (based on the presence of the cytogenetic abnormality that the WHO classifies as AML with myelodysplasia-related cytogenetic abnormality) are considered reasonable treatment options.  NCRI AML 19 trial has shown benefit of Flag Ida Go in NPM1 mutated subgroup but these data are currently not published as a full manuscript and the drug combination is not licensed. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA Midostaurin.  Ratify trial showed the survival benefit of adding Midostaurin to both ITD and TKD mutated patients irrespective of the NPM-1 co-mutational status.  Preliminary results of the AML 19 trial have demonstrated the feasibility and safety of adding Midostaurin to DA+GO, but publication of the full report is awaited and this combination is not licensed or funded as standard of care treatment."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML NOS\n| DIAGNOSIS ICC: AML NOS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug Choices: DA+GO, DA+Midostaurin or DA"
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment Either DA+Midostaurin (based on the presence of a FLT3 variant and the results of the RATIFY study) or CPX (based on the benefits in patients with an antecedent myelodysplastic syndrome) are considered reasonable treatment options. Although the CPX trial was designed for patients over 60, real world evidence supports the age-agnostic benefits of this drug in this setting, for which it is licenced."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "TC_18 young patient - Preferred Treatment & FLAG-IDA preference\nTC_18 older patient â€“ Preferred Treatment only\n\n|__________ ANTECEDENTS _________\n| Prior MDS \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML NOS progressing from MDS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug Choices: DA+Midostaurin, CPX-351 or DA"
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment Either DA+Midostaurin (based on the presence of a FLT3 variant) or CPX (based on the presence of the cytogenetic abnormality that the WHO classifies as AML with myelodysplasia-related cytogenetic abnormality) are considered reasonable treatment options.  NCRI AML 19 trial has shown benefit of Flag Ida Go in NPM1 mutated subgroup but these data are currently not published as a full manuscript and the drug combination is not licensed. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Mido use. Ratify trial showed benefit of adding Midostaurin to DA irrespective of the FLT3 allele burden or NPM1 mutational status. AML 19 trial results in abstract form indicate the benefit of FLAG-Ida + GO over DA + GO but no head to head comparison with DA+Mido and FLAG-Ida + GO is not licensed."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus. Both CPX or DA+ Midostaurin are options, in the absence of a direct comparison of DA+ midostaurin with CPX in patients with secondary AML (therapy-related, previous MDS or with MDS-related cytogenetic abnormality) and concomitant FLT3 variants. DA alone is not recommended. The CPX trial was designed for patients with secondary AML over 60 but real world data demonstrates benefits even in younger patients.  The drug is licensed, with regulatory approval in the United Kingdom for use in AML with a myelodysplasia-related cytogenetic abnormality, present in this patient.  Likewise, the addition of midostaurin to DA is a licensed and approved drug combination in AML patients with FLT3 "
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "TC_20 young patient - Preferred Treatment & FLAG-IDA preference\nTC_20 older patient â€“ Preferred Treatment only\n\n|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Monosomy 7\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with myelodysplasia related cytogenetic abnormality\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: DA+Midostaurin, CPX-351 or DA"
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Mido use. Ratify trial showed the benefit of adding Midostaurin to DA irrespective of whether FLT3 ITD or TKD mutation was present, with benefit across ELN 2017 risk groups observed in sub-analysis."
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| t(10;11)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML KMT2A-rearranged\n| DIAGNOSIS ICC: AML KMT2A-rearranaged\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: DA+Midostaurin or DA"
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment Either DA+Midostaurin (based on the presence of a FLT3 variant) or CPX (based on the presence of the cytogenetic abnormality that the WHO classifies as AML with myelodysplasia-related cytogenetic abnormality) are considered reasonable treatment options.  NCRI AML 19 trial has shown benefit of Flag Ida Go in NPM1 mutated subgroup but these data are currently not published as a full manuscript and the drug combination is not licensed. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "In the absence of a direct comparison of CPX and DA + Midostaurin in patients with concurrent MDS-related cytogenetic abnormalities and FLT3 variants, CPX or DA + midostaurin remain options, based on the improved survival observed in trials of the drugs compared to DA, mainly in patients without this patient’s genetic profile.  The experts acknowledge the dismal prognosis in patients with this particular combination of genomic abnormalities with currently licensed or conventional therapies including CPX and FLAG-Ida (AML-19 data) and have recommended that this patient be considered for treatment in the context of a clinical trial. "
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===true && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go as the concurrent FLT3 variant does not alter the favourable risk conferred by the core binding factor t(8;21) re-arrangement.  The consensus for DA+Go is based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(8;21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-LOW\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS: AML 8_21\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: t 8 21\n\nDrug choice: DA+GO, DA+Mido, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "In the absence of a DA+GO option (which some experts stated as the preference), there is strong consensus for DA + midostaurin, based on the presence of FLT3-ITD.  CPX is not considered a good evidence-based option  despite the diagnosis of therapy-related AML, since patients known to have favourable risk cytogenetic abnormalities in therapy-related AML were excluded from the randomised trial comparing CPX with DA.  The consensus for DA+ midostaurin is influenced by the survival benefit observed with the addition of midostaurin to DA in the RATIFY study of patients between 18-59 years of age, and in the phase 2 AML SG 60-10 study that included older patients.  However, patients with therapy-related AML were excluded from RATIFY and those with favourable cytogenetics were ineligible for the AML SG 60-10 study weakening the evidence-base for this consensus."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|Prior therapy for another malignancy \n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(8;21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages) \n|__________ RESULTS _____________\n| DIAGNOSIS: therapy-related AML\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: t 8 21 \n\nDrug choice: DA+Mido, CPX-351, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Go in AML with a core binding factor t(8;21) re-arrangement that confers favourable risk.  The consensus for DA+Go is based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(8;21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS: AML 8_21\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: t 8 21\n\nDrug choice: DA+GO, DA "
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Go to be the treatment of choice as favourable cytogenetic changes based on Swedish registry data (even though technically not funded on NHS).  Alfa 0701 trial was exclusively designed for de novo AML and the CPX study excluded patients with known CBF AML."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| Prior cytotoxic therapy for non-haem malignancy \n|__________ CYTOGENETIC _________\n| inv(16)\n| t(1;10)\n| t(1;4)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: t-AML\n| DIAGNOSIS ICC: AML with inv(16), therapy related\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX or DA + GO are considered as options, due to superior outcomes compared to DA in respective clinical trials.  CPX is licensed and approved therapy for patients with AML with myelodysplasia-related cytogenetic abnormality [del(12p) in this case], but the concurrent presence of the NPM-1 mutation (commonly associated with normal karyotype AML and not a well-represented genotype in the CPX study) has made some experts prefer DA+GO, irrespective of patient age."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * del(12p)\n|__________ NPM1 ________________\n| MUTATED \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS: AML MRC\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: NPM1mut FLT3-ITDneg\n\nDrug choice: DA+GO, CPX-351, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go based on the licensing Alfa 0701 trial and meta-analysis of RCTs demonstrating survival benefit with the inclusion of GO in induction therapy in AML with non-adverse karyotype. Morphological appearance alone is not sufficient to define AML-MRC in the absence of cytogentic and mutational panel supporting MDS like changes"
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| Mutated NPM1 \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML with mutated NPM1\n| DIAGNOSIS ICC: AML with mutated NPM1\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable\n\nDrug Choices: DA+GO or DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "CPX"
		Strength_of_consensus = "Strong"
		Comment = "Based on the superior survival of patients treated with CPX compared to DA in a phase 3 RCT, CPX is licensed and has regulatory approval for use in AML patients with antecedent MDS or CMML. In older patients, there is strong consensus that favours CPX, based on the trial results."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n|Known MDS or CMML \n|__________ CYTOGENETIC _________\n| 0 cytogenetic changes:\n|Normal cytogenetics\n|__________ NPM1 ________________\n| MUTATED \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages) \n|__________ RESULTS _____________\n| DIAGNOSIS: AML MRC\n| PROGNOSTIC GROUP 1: FAVOURABLE\n| PROGNOSTIC GROUP 2: NPM1 pos FLT3 ITD neg\n\nDrug choices: CPX-351, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus exists regarding the optimal treatment in older patients. Both DA+GO and CPX are preferred over DA based on RCT data showing improved efficacy in intermediate risk disease and AML MRC respectively. No head to head data exists comparing the efficacy of DA+GO to CPX in this scenario of cytogenetically defined AML MRC without adverse karyotype."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * t(1;3)(p36;q21)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)  \n|__________ RESULTS _____________\n| DIAGNOSIS: AML MRC\n| PROGNOSTIC GROUP 1: INTERMEDIATE\n| PROGNOSTIC GROUP 2: Intermediate risk cytogenetic changes\n\nDrug choices: DA+GO, CPX-351, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA+Midostaurin. Ratify trial included patients only upto the age of 59. However the phase 2 AML SG trial that included older patients provides data to support the use of Midostaurin in older AML patients, when compared to a historical cohort (1993-2009) of patients. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-LOW\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML_NOS\n| DIAGNOSIS ICC: AML_NOS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug choices: DA+GO, DA+Mido, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX and DA + midostaurin are considered as options. DA alone is not recommended. The CPX trial was designed for patients over 60 and Ratify trial was designed for patients under 60yrs but the effectiveness of midostaurin with DA in older patients is evident from the sub-analysis of the Phase 2 AML SG 60-10 study comparing outcomes with a historical cohort of patients. This study too did not exclude patients with secondary AML from participation.  Real world evidence too supports the use of both therapies in patients over 18yrs."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| Prior MDS\n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-LOW\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages) \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML NOS progressing from MDS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug choices: DA+Mido, CPX-351, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go based on the licensing Alfa 0701 trial and meta-analysis of RCTs demonstrating survival benefit with the inclusion of GO to DA in induction therapy in AML with non-adverse karyotype."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML NOS\n| DIAGNOSIS ICC: AML NOS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug Choices: DA+GO or DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "CPX"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for CPX. The CPX trial was designed for patients over the age of 60yrs, and showed a survival benefit over DA in AML patients with an antecedent myelodysplastic syndrome.  "
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| Prior MDS \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML NOS progressing from MDS\n| 2017 ELN: Intermediate risk\n| 2022 ELN: Intermediate risk\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Either DA+Midostaurin or CPX remain an option and both are preferred over DA. Experts commented on difficulty of selecting between CPX and DA+Midostaurin in absence of head to head RCT data. In those patients not likely to be good transplant candidates DA+Midostaurin offers potential upside of targeted maintenance therapy."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 1 cytogenetic change:\n| * del(12p)\n|__________ NPM1 ________________\n| MUTATED \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with mutated NPM1\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug choices: DA+GO, DA+Mido, CPX-351, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX and DA + Midostaurin are considered as options, in the absence of suitably powered studies that directly compare DA+ midostaurin and CPX in similar patients.  DA or DA + GO are not recommended in this setting due to the existence of licensed and approved therapy (CPX) for patients with AML with myelodysplasia-related cytogenetic abnormality (complex karyotype in this case), based on RCT data demonstrating survival benefit with CPX compared to DA.  Although this trial recruited patients over 60 years of age, real world data that includes younger patients, and the CPX arm of the AML19 trial in patients with adverse cytogenetics demonstrate similar outcomes in younger patients.  Although a DA+GO vs CPX comparison is lacking in younger patients, previous studies have demonstrated the lack of benefit with DA + GO compared to DA in patients with adverse cytogenetic risk.  It would therefore be appropriate to extend the results of the CPX vs DA trial to younger patients.  The Ratify and AMLSG 60-10 studies indicate the superiority of DA + midostaurin over DA in younger and older patients with FLT3 mutant AML, making this the preferred option for some experts who view the perceive the benefits of targeting FLT3 as superseding those conferred by CPX in patients with complex karyotype.  Although numbers of patients with a similar genotype were small in the CPX and RATIFY studies, sub-group analysis suggests the benefits of both CPX and DA+ midostaurin over DA in the respective studies, justifying the use of either drug/combination in this particular patient."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 3 cytogenetic changes:\n| * isochromosome(17q)\n| * del(4p)\n| * del(8q)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with MR cytogenetic abnormality\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug choices: CPX-351, DA+Mido, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "CPX"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for CPX use. The CPX trial demonstrated the benefits of CPX over DA in secondary AML including AML with myelodysplasia-related cytogenetic abnormalities."
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Monosomy 7\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with myelodysplasia related cytogenetic abnormality\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "DA is the only funded treatment for this scenario (apart from FLAG-Ida – see notes below). Neither DA+GO or CPX are funded on the NHS for this scenario. The addition of GO to DA is not beneficial in adult patients with most adverse risk genetics which may have led to some experts favouring DA alone; others may have been influenced by the possible benefits of GO on event-free survival reported in children with KMT2A re-arranged disease.   CPX has been used in adverse risk patients in AML19 and shown similar efficacy to FLAG-IDA."
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| t(10;11)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML KMT2A-rearranged\n| DIAGNOSIS ICC: AML KMT2A-rearranaged\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: DA only"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "In the absence of a direct comparison of CPX and DA + Midostaurin in patients with concurrent MDS-related cytogenetic abnormalities and FLT3 variants, CPX or DA + midostaurin remain options, based on the improved survival observed in trials of the drugs compared to DA, mainly in patients without this patient’s genetic profile.  The experts acknowledge the dismal prognosis in patients with this particular combination of genomic abnormalities with currently licensed or conventional therapies including CPX and FLAG-Ida (AML-19 data) and have recommended that this patient be considered for treatment in the context of a clinical trial. "
		Alternative_treatment = "Moderate consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case\n|__________ CYTOGENETIC _________\n| 3 cytogenetic changes:\n| * isochromosome(17q)\n| * del(4p)\n| * del(8q)\n|__________ TP53 ________________\n| Mutated TP53. VAF>10% \n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| MUTATED-HIGH\n|__________ FLT3-TKD ____________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with mutated TP53\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug choices: CPX-351, DA+Mido, DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "CPX"
		Strength_of_consensus = "Moderate"
		Comment = "There is moderate consensus for the use of CPX, based the randomised clinical trial indicating improved survival with CPX compared to DA in unselected patients over the age of 60 years with AML and myelodysplasia-related cytogenetic abnormalities. However, the experts also acknowledge the futility of licensed and approved therapies with the patient’s disease genotype and recommend enrolment of the patient in a suitable clinical trial."
		Alternative_treatment = "Moderate consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Monosomy 7\n| Del(10p)\n| Del(8q)\n|__________ TP53 ________________\n| Mutated TP53. VAF>10% \n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| WILD TYPE\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with mutated TP53\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: CPX-351 or DA"
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===false && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go as the concurrent FLT3 variant does not alter the favourable risk conferred by the core binding factor t(8;21) re-arrangement.  The consensus for DA+Go is based on the results of the Alfa 0701 trial that led to GO being licensed for use in AML and the meta-analysis of RCTs including the MRC studies, demonstrating survival benefit with the addition of GO to chemotherapy in AML with non-adverse karyotype, in an age-agnostic manner."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+GO"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus to give DA+Go as concurrent FLT3 variant or additional chromosomal abnormalities do not alter the favourable risk conferred by the presence of a CBF re-arrangement.  The Alfa 0701 trial was exclusively designed for denovo AML and the CPX study did not recruit patients with known CBF re-arrangements."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| Prior cytotoxic therapy for non-haem malignancy \n|__________ CYTOGENETIC _________\n| inv(16)\n| t(1;10)\n| t(1;4)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: t-AML\n| DIAGNOSIS ICC: AML with inv(16), therapy-related\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable\n\nDrug Choices: CPX-351, DA+Midostaurin or DA"
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA + Midostaurin.  The Ratify trial showed the survival benefit of adding midostaurin to both ITD and TKD mutated patients irrespective of the NPM-1 co-mutational status.  Although this trial included patients only upto the age of 59 years, the AML SG 60-10 phase 2 study provides support for the benefits of midostaurin even in older patients. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "TC_14 young patient â€“ Preferred Treatment only\nTC_14 older patient â€“ Preferred Treatment only\n\n|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| Mutated NPM1 \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML with mutated NPM1\n| DIAGNOSIS ICC: AML with mutated NPM1\n| 2017 ELN: Favourable\n| 2022 ELN: Favourable \n\nDrug Choices: DA+GO, DA+Midostaurin or DA"
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "Both CPX and DA + midostaurin are considered as options. DA alone is not recommended. The CPX trial was designed for patients over 60 and Ratify trial was designed for patients under 60yrs but the effectiveness of midostaurin with DA in older patients is evident from the sub-analysis of the Phase 2 AML SG 60-10 study comparing outcomes with a historical cohort of patients. This study too did not exclude patients with secondary AML from participation.  Real world evidence too supports the use of both therapies in patients over 18yrs."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment Either DA+Midostaurin (based on the presence of a FLT3 variant) or CPX (based on the presence of the cytogenetic abnormality that the WHO classifies as AML with myelodysplasia-related cytogenetic abnormality) are considered reasonable treatment options.  NCRI AMl trial has shown benefit of Flag Ida Go in NPM1 mutated subgroup but these data are currently not published as a full manuscript and the drug combination is not licensed. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Favourable risk non CBF" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA+Midostaurin. Ratify trial showed benefit of adding Midostaurin to both ITD and TKD mutated patients, irrespective of allele burden or NPM-1 co-mutation status. Ratify trial included patients only upto the age of 59. However the phase 2 AML SG trial that included older patients provides data to support the use of Midostaurin in older AML patients, when compared to a historical cohort (1993-2009) of patients. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML NOS\n| DIAGNOSIS ICC: AML NOS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug Choices: DA+GO, DA+Midostaurin or DA"
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "There was no consensus. Both CPX and Da+Midostaurin are considered as options. No consensus for Flag Ida use. CPX trial was designed for patients over 60 and Ratify trial was designed for patients under 60yrs. Real world evidence support use of both over 18yrs"
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = "TC_18 young patient - Preferred Treatment & FLAG-IDA preference\nTC_18 older patient â€“ Preferred Treatment only\n\n|__________ ANTECEDENTS _________\n| Prior MDS \n|__________ CYTOGENETIC _________\n| Normal cytogenetics\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML NOS progressing from MDS\n| 2017 ELN: Intermediate\n| 2022 ELN: Intermediate\n\nDrug Choices: DA+Midostaurin, CPX-351 or DA"
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Moderate"
		Comment = "Moderate consensus for DA+Midostaurin use. CPX remains an option and both are preferred over DA. Experts commented on difficulty of selecting between CPX and DA+Midostaurin in absence of head to head RCT data. In those patients not likely to be good transplant candidates DA+Midostaurin offers potential upside of targeted maintenance therapy."
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Intermediate risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Consensus for DA Midostaurin use. Ratify trial showed benefit of adding Midostaurin to DA irrespective of the FLT3 allele burden or NPM1 co-mutation status. Ratify trial included patients only upto the age of 59. However the phase 2 AML SG trial that included older patients provides data to support the use of Midostaurin in older AML patients, when compared to a historical cohort (1993-2009) of patients. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus. Both CPX or DA+ Midostaurin are options, in the absence of a direct comparison of DA+ midostaurin with CPX in patients with secondary AML (therapy-related, previous MDS or with MDS-related cytogenetic abnormality) and concomitant FLT3 variants. DA alone is not recommended. The CPX trial was designed for patients with secondary AML over 60 but real world data demonstrates benefits even in younger patients.  The drug is licensed, with regulatory approval in the United Kingdom for use in AML with a myelodysplasia-related cytogenetic abnormality, present in this patient.  Likewise, the addition of midostaurin to DA is a licensed and approved drug combination in AML patients with FLT3. Ratify trial included patients only upto the age of 59. However the phase 2 AML SG trial that included older patients provides data to support the use of Midostaurin in older AML patients, when compared to a historical cohort (1993-2009) of patients. "
		Alternative_treatment = "Strong consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "TC_20 young patient - Preferred Treatment & FLAG-IDA preference\nTC_20 older patient â€“ Preferred Treatment only\n\n|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| Monosomy 7\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. \n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML MRC\n| DIAGNOSIS ICC: AML with myelodysplasia related cytogenetic abnormality\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: DA+Midostaurin, CPX-351 or DA"
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "DA+Midostaurin"
		Strength_of_consensus = "Strong"
		Comment = "Strong consensus for DA+Mido use. Ratify trial showed the benefit of adding Midostaurin to DA irrespective of whether FLT3 ITD or TKD mutation was present, with benefit across ELN 2017 risk groups observed in sub-analysis. Ratify trial included patients only upto the age of 59. However the AML SG 60-10 trial data provided support for the use of Midostaurin in older AML patients even if only 5% of patients in the entire study that recruited patients over 18 years of age, had adverse risk cytogenetic abnormalities. "
		Alternative_treatment = "Moderate consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = "|__________ ANTECEDENTS _________\n| New case \n|__________ CYTOGENETIC _________\n| t(10;11)\n|__________ NPM1 ________________\n| WILD TYPE \n|__________ FLT3-ITD ____________\n| WILD TYPE\n___________FLT3-TKD_______________\n| Mutated TKD\n|__________ MORPHOLOGY __________\n| >20% blasts. Multi-lineage dysplasia (greater than 50% in 2 lineages)\n|__________ RESULTS _____________\n| DIAGNOSIS WHO: AML KMT2A-rearranged\n| DIAGNOSIS ICC: AML KMT2A-rearranaged\n| 2017 ELN: Adverse\n| 2022 ELN: Adverse\n\nDrug Choices: DA+Midostaurin or DA"
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "No consensus on treatment.  Both DA+Midostaurin or CPX are preferred over DA. Experts commented on difficulty of selecting between CPX and DA+Midostaurin in absence of head to head RCT data. In those patients not likely to be good transplant candidates DA+Midostaurin offers potential upside of targeted maintenance therapy. "
		Alternative_treatment = "No consensus to support FLAG-Ida as altenative treatment, however it remains a viable alternative according to some experts"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "No consensus"
		Strength_of_consensus = "NA"
		Comment = "In the absence of a direct comparison of CPX and DA + Midostaurin in patients with concurrent MDS-related cytogenetic abnormalities and FLT3 variants, CPX or DA + midostaurin remain options, based on the improved survival observed in trials of the drugs compared to DA, mainly in patients without this patient’s genetic profile.  The experts acknowledge the dismal prognosis in patients with this particular combination of genomic abnormalities with currently licensed or conventional therapies including CPX and FLAG-Ida (AML-19 data) and have recommended that this patient be considered for treatment in the context of a clinical trial. "
		Alternative_treatment = "Moderate consensus that the use of FLAG Ida is considered a reasonable alternative. Patients with a similar clincopathological profile were eligible for recruitment in the UK AML 15, 17 and 19 trials. In AML15, patients treated with Flag Ida had higher response rates over ADE/DA following cycle 1 and superior relapse-free survival but not overall survival.  However, sub-analysis of patients with secondary AML in this trial (n=115) appeared to indicate a survival advantage for FLAG-Ida, once the stratified analysis was adjusted for genetics.  There are currently no published data comparing FLAG-Ida with DA + midostaurin, and numbers of patients with an identical genomic profile are very few in the ‘high-risk’ arm of AML19 for a meaningful comparison of FLAG-Ida versus CPX."
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===true && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===true && CPX===true && Midostaurin===true) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	} else
	if (age===false && FLT3_TKD===true && ELN2022_TP53==="Adverse risk_TP53mut_CK" && DA_GO===false && CPX===false && Midostaurin===false) {
		Preferred_Treatment_Option = "This scenario is considered biologically implausible with current NHS treatment licensing"
		Strength_of_consensus = "NA"
		Comment = "NA"
		Alternative_treatment = "NA"
		Text = ""
	}

	return {
		Preferred_Treatment_Option,
		Strength_of_consensus,
		Comment,
		Alternative_treatment,
		Text
	}
}

export default function ConsensusCard(props) {

	const [age, set_age] = useState(false)
	const [FLT3_TKD, set_FLT3_TKD] = useState(false)
	const [ELN2022_TP53, set_ELN2022_TP53] = useState('')
	const [DA_GO, set_DA_GO] = useState(false)
	const [CPX, set_CPX] = useState(false)
	const [Midostaurin, set_Midostaurin] = useState(false)

	const [show, set_show] = useState(false)

	const [Preferred_Treatment_Option, set_Preferred_Treatment_Option] = useState('');
	const [Strength_of_consensus, set_Strength_of_consensus] = useState('');
	const [Comment, set_Comment] = useState('');
	const [Alternative_treatment, set_Alternative_treatment] = useState('');
	const [Text, set_Text] = useState('');

	const update_treatment = () => {
		const r = get_section(age, FLT3_TKD, ELN2022_TP53, DA_GO, CPX, Midostaurin);
		set_Preferred_Treatment_Option(r.Preferred_Treatment_Option);
		set_Strength_of_consensus(r.Strength_of_consensus);
		set_Comment(r.Comment);
		set_Alternative_treatment(r.Alternative_treatment);
		set_Text(r.Text);
	}

	const onClick = () => set_show(!show)
	const SText = () => (
		<div>{Text.split ('\n').map ((item, i) => <p key={i}>{item}</p>)}</div>
	)

	return (
		<div>
			<div className="card">
				<div className="card-body">
					<h5 className="card-title">Input</h5>
					<input className="form-check-input" type="checkbox" checked={age} id="AgeLT60" onChange={(e) => set_age(e.target.checked)}/>
					<label className="form-check-label" htmlFor="AgeLT60">&nbsp; Age &lt; 60</label>
					<p></p>
					<input className="form-check-input" type="checkbox" checked={FLT3_TKD} id="FLT3_TKD" onChange={(e) => set_FLT3_TKD(e.target.checked) }/>
					<label className="form-check-label" htmlFor="FLT3_TKD">&nbsp; FLT3-TKD mutation present but no FLT3-ITD mutation</label>
					<p></p>
					<label className="form-check-label" htmlFor="ELN">ELN Risk Group</label>
					<select class="form-select" id="ELN" name="ELN" aria-label="Default select example" onChange={(e)=>set_ELN2022_TP53(e.target.value)} value={ELN2022_TP53}>
					<option value=""> </option>
					<option value="Favourable risk CBF">Favourable risk CBF</option>
					<option value="Favourable risk non CBF">Favourable risk non CBF</option>
					<option value="Intermediate risk">Intermediate risk</option>
					<option value="Adverse risk">Adverse risk</option>
					<option value="Adverse risk_TP53mut_CK">Adverse risk Complex karyotype + TP53</option>
					<option value="Unclear risk_NPM1 and TP53mut">ELN risk category unclear</option>
					</select>
					<p></p>
					<input className="form-check-input" type="checkbox" checked={DA_GO} id="DA_GO" onChange={(e) => set_DA_GO(e.target.checked) }/>
					<label className="form-check-label" htmlFor="DA_GO">&nbsp; Eligible for DA+GO</label>
					<p></p>
					<input className="form-check-input" type="checkbox" checked={CPX} id="CPX" onChange={(e) => set_CPX(e.target.checked) }/>
					<label className="form-check-label" htmlFor="CPX">&nbsp; Eligible for CPX</label>
					<p></p>
					<input className="form-check-input" type="checkbox" checked={Midostaurin} id="Midostaurin" onChange={(e) => set_Midostaurin(e.target.checked) }/>
					<label className="form-check-label" htmlFor="Midostaurin">&nbsp; Eligible for DA+Midostaurin</label>
					<p></p>
					<a href="#" className="btn btn-primary" onClick={() => {update_treatment()}}>Run</a>
				</div>
			</div>

			<div className="card text-white bg-primary">
				<div className="card-body">
				<div style={{marginTop: '20px'}}>
					<h2>Treatment Recommendation</h2>
					<br/>
					<p>Based on the information added to the webapp the following information has been inputted to establish the treatment recommendations (as per methodology used in Coats et al 2021) updated in 2023</p>
					<br/>
					<p>Consensus was based on the opinions of at least 10 UK AML experts. Strong consensus requires &ge; 85% respondents agreeing on chosen treatment/statement to support drug use. Moderate consensus requires 75-85% agreement. No consensus indicates &lt;75% of respondents selected agreeing on chosen treatment</p>
					<br/>
					<p><b>Preferred_Treatment_Option</b></p>
					<p>{Preferred_Treatment_Option}</p>
					<br/>
					<p><b>Strength_of_consensus</b></p>
					<p>{Strength_of_consensus}</p>
					<br/>
					<p><b>Comment</b></p>
					<p>{Comment}</p>
					<br/>
					<p><b>Alternative_treatment</b></p>
					<p>{Alternative_treatment}</p>
					<br/>
					{Text!=="" ? <p onClick={onClick}><b><u>Click to show the example case used to generate the consensus</u></b></p> : null }
					{show ? <SText /> : null }
				</div>
				</div>
			</div>
	  </div>
	);
  }