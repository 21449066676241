import React, { useState } from 'react';

const abnormal_list = ['NPM1_mut',
'CEBPA_biallelic_mut',
'CEBPA_bZIP_mut',
'inv(16)(p13.1q22)',
't(16;16)(p13.1;q22)',
't(8;21)(q22;q22.1)',
't(9;11)(p21.3;q23.3)',
'inv(3)(q21.3q26.2)',
't(3;3)(q21.3;q26.2)',
't(v;11q23.3)',
't(1;3)(p36;q21)',
'Other rare genetic alteration',
'del(17)(q21.2q21.2)',
'inv(16)(p13.3q24.3)',
't(10;11)(p12.3;q14.2)',
't(11;12)(p15.4;p13.3)',
't(15;17)(q24.1;q21.2)',
't(16;21)(p11.2;q22.2)',
't(16;21)(q24.3;q22.1)',
't(1;22)(p13.3;q13.1)',
't(1;3)(p36.3;q21.3)',
't(3;5)(q25.3;q35.1)',
't(3q26.2;v)',
't(5;11)(q35.2;p15.4)',
't(6;9)(p22.3;q34.1)',
't(7;12)(q36.3;p13.2)',
't(8;16)(p11.2;p13.3)',
't(9;22)(q34.1;q11.2)',
't(v;11p15.4)',
't(v;17q21.2)'];

const abnormal_map = {
	"NPM1_mut": {
		"NPM1_mut": false,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"CEBPA_biallelic_mut": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": false,
		"CEBPA_bZIP_mut": false,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"CEBPA_bZIP_mut": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": false,
		"CEBPA_bZIP_mut": false,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"inv(16)(p13.1q22)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": false,
		"t(16;16)(p13.1;q22)": false,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(16;16)(p13.1;q22)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": false,
		"t(16;16)(p13.1;q22)": false,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(8;21)(q22;q22.1)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": false,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(9;11)(p21.3;q23.3)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": false,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": false,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"inv(3)(q21.3q26.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": false,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": false,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(3;3)(q21.3;q26.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": false,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": false,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(v;11q23.3)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": false,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": false,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(1;3)(p36;q21)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": false,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"Other rare genetic alteration": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": false,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"del(17)(q21.2q21.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": false,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": false,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": false
	},
	"inv(16)(p13.3q24.3)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": false,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(10;11)(p12.3;q14.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": false,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(11;12)(p15.4;p13.3)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": false,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": false,
		"t(v;17q21.2)": true
	},
	"t(15;17)(q24.1;q21.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": false,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": false,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": false
	},
	"t(16;21)(p11.2;q22.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": false,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(16;21)(q24.3;q22.1)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": false,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(1;22)(p13.3;q13.1)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": false,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(1;3)(p36.3;q21.3)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": false,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(3;5)(q25.3;q35.1)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": false,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(3q26.2;v)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": false,
		"t(3;3)(q21.3;q26.2)": false,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": false,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(5;11)(q35.2;p15.4)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": false,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": false,
		"t(v;17q21.2)": true
	},
	"t(6;9)(p22.3;q34.1)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": false,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(7;12)(q36.3;p13.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": false,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(8;16)(p11.2;p13.3)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": false,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(9;22)(q34.1;q11.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": false,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": true
	},
	"t(v;11p15.4)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": true,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": false,
		"t(15;17)(q24.1;q21.2)": true,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": false,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": false,
		"t(v;17q21.2)": true
	},
	"t(v;17q21.2)": {
		"NPM1_mut": true,
		"CEBPA_biallelic_mut": true,
		"CEBPA_bZIP_mut": true,
		"inv(16)(p13.1q22)": true,
		"t(16;16)(p13.1;q22)": true,
		"t(8;21)(q22;q22.1)": true,
		"t(9;11)(p21.3;q23.3)": true,
		"inv(3)(q21.3q26.2)": true,
		"t(3;3)(q21.3;q26.2)": true,
		"t(v;11q23.3)": true,
		"t(1;3)(p36;q21)": true,
		"Other rare genetic alteration": true,
		"del(17)(q21.2q21.2)": false,
		"inv(16)(p13.3q24.3)": true,
		"t(10;11)(p12.3;q14.2)": true,
		"t(11;12)(p15.4;p13.3)": true,
		"t(15;17)(q24.1;q21.2)": false,
		"t(16;21)(p11.2;q22.2)": true,
		"t(16;21)(q24.3;q22.1)": true,
		"t(1;22)(p13.3;q13.1)": true,
		"t(1;3)(p36.3;q21.3)": true,
		"t(3;5)(q25.3;q35.1)": true,
		"t(3q26.2;v)": true,
		"t(5;11)(q35.2;p15.4)": true,
		"t(6;9)(p22.3;q34.1)": true,
		"t(7;12)(q36.3;p13.2)": true,
		"t(8;16)(p11.2;p13.3)": true,
		"t(9;22)(q34.1;q11.2)": true,
		"t(v;11p15.4)": true,
		"t(v;17q21.2)": false
	}
}

function ResultItem(props){
	return (
		<li>{props.name}: {props.data.value} ({props.data.status})</li>
	)
}

const disable_link = (b) => b ? {color:'black'}: {pointerEvents: 'none', color: 'gray'}

export default function ResultsCard(props) {
	const [ack, set_ack] = useState(false)
	const [age, set_age] = useState(true)
	let res = []
	if(props.status.hasOwnProperty('Layer_0')){
		res = Object.keys(props.status.Layer_0)
			.filter((el) => el.slice(0,3)=='AML' || el.slice(0,3)=='ICC' || el.slice(0,3)=='ELN')
			.map((el)=> <ResultItem key={el} name={el} data={props.status.Layer_0[el]} />)
	}
	let hide = props.error_state ? '' : "hidden"
	const warnings = props.error_message.map((msg, i) => {
		return (<div key={i} className="alert alert-warning" role="alert">
					{msg}
				</div>)
	})

	let show_message = false;
	// check error
	if (props.status.Layer_0['AML_WHO_v4'].status == 'Error' || props.status.Layer_0['AML_WHO_v5'].status == 'Error' || props.status.Layer_0['ICC_v1'].status == 'Error') {
		let abnormal = [];
		for (let i=0;i<abnormal_list.length;i++)
			if (abnormal_list[i] in props.data && props.data[abnormal_list[i]] == true)
				abnormal = [...abnormal, abnormal_list[i]];
		if (abnormal.length > 1)
			for (let i=0;i<abnormal.length;i++) {
				for (let j=i+1;j<abnormal.length;j++)
					if (abnormal_map[abnormal[i]][abnormal[j]] == true)
						show_message = true;
			}
	}
	let hide2 = show_message ? '' : 'hidden'
	
	return (
		<div>
		<div className="card text-white bg-primary">
		<div className="card-body">
		<a href="#" className="btn btn-light float-end" onClick={()=>props.reset()}>Reset</a>
		  <h5 className="card-title">Results</h5>
		  <ul>
			  {res}
		  </ul>
		  <div className={hide}>
			{warnings}
		  </div>
		  <div className={hide2}>
		  	<p>Patient appears to have two or more recurrently occurring cytogenetic/molecular changes making classification difficult</p>
		  </div>
		</div>
	  </div>
	  		<div className="card">
			<div className="card-body">
			<div className="card-text">
			<p>Would you like to proceed to 1st line intensive treatment eligibility recommendations for AML?</p>
			<br/>
			<p>Please indicate the age:</p>
			<input className="form-check-input" type="checkbox" checked={age} id="AgeLT60" onChange={(e) => set_age(e.target.checked) }/>
			<label className="form-check-label" htmlFor="AgeLT60">&nbsp; Age &lt; 60</label>
			<input className="form-check-input" type="checkbox" checked={!age} id="AgeGTE60" onChange={(e) => set_age(!e.target.checked) } style={{marginLeft: '20px'}}/>
			<label className="form-check-label" htmlFor="AgeGTE60">&nbsp; Age &ge; 60</label>
			<p><br/></p>
			<p>NB these recommendations for 1st line intensive  AML treatment. They do not refer to AML arising from prior MPN, APL, biphenotypic leukaemia, myeloid sarcoma or previously treated AML or non-intensive treatment. These treatment recommendations are based on having results of NPM1 and flt 3 mutation status.  If a treatment decision is to be made before full karyotype is back then a normal karyotype should be assumed (unless FISH results demonstrate a relevant abnormality).  These treatment recommendations do not integrate results of additional mutations e.g. ASXL1, U2AF1 etc.  The exception to this is if TP53 mutation status is detected in the context of a complex karyotype.  There are specific treatment recommendations for this scenario.</p>
			<input className="form-check-input" type="checkbox" checked={ack} id="ResultAck" onChange={(e) => set_ack(e.target.checked) }/>
			<label className="form-check-label" htmlFor="ResultAck">&nbsp; Acknowledgment</label>
			</div>
			<p></p>
			<a href="#" className="btn btn-light" onClick={()=>props.back()}>Back</a>
			<a href="#" className="btn btn-light float-end" style={disable_link(ack)} onClick={()=>{ if (ack) props.results_next({ack:ack, age:age}); }}>Next</a>
			</div>
			</div>
	  </div>
	);
  }