import React from 'react';

const disable_link = (b) => b ? {color:'white'}: {pointerEvents: 'none', color: 'black'}

export default class Card extends React.Component {
	constructor(props) {
	super(props);
	this.state = this.props.values
	this.update_data = this.update_data.bind(this)
	}
	static defaultProps = {
		error_message: [],
		error_state: false
	}
	
	update_data(e) {
		let k = e.name
		let v
		if(e.type === 'checkbox'){
			v = e.checked
		}
		if(e.type === 'number'){
			v = parseFloat(e.value)
		}
		
		let update = {}
		update[k] = v

		this.setState(update) 
	}

	render() {
		let items = []
		let el_id, el_label, type, in_el, sec
		const sections = {}
		for (const section_name of this.props.conf.sections) {
			sections[section_name] = []
			if(section_name !== ""){
				sections[section_name].push(<p className="card-text"><strong>{section_name}</strong></p>)
			}
		}
		console.log(sections)
		for (const vname of this.props.conf.variables) {
			el_id = `checkbox_${vname}`
			el_label = this.props.all_variables[vname].label
			type = "bool"
			if(this.props.all_variables[vname].hasOwnProperty('type')){
				type = this.props.all_variables[vname].type
			} 
			if(type === 'bool'){
				in_el = (<div key={vname} class="col mb-3 form-check">
				<input type="checkbox" className="form-check-input" id={el_id} name={vname} checked={this.state[vname]} onChange={(e)=>this.update_data(e.target)}/>
				<label className="form-check-label" htmlFor={el_id}>{el_label}</label>
				</div>)
			} 
			if(type === 'num'){
				in_el = (<div key={vname} class="col mb-3">
				<label className="form-label" htmlFor={el_id}>{el_label} <span style={{color:'blue'}}>(Compulsory)</span></label>
				<input type="number" className="form-control" id={el_id} name={vname} value={this.state[vname]} onChange={(e)=>this.update_data(e.target)}/>
				</div>)
			}
			sec = this.props.all_variables[vname].hasOwnProperty('section') ? this.props.all_variables[vname].section : ''
			console.log('adding', vname, 'to section', sec)
			sections[sec].push( in_el )
		}
		for (const section_name of this.props.conf.sections) {
			let bit
			if(sections[section_name].length > 6){
				bit = (
					<div className="row row-cols-3">
						{sections[section_name]}
					</div>
				)
			} else {
				bit = (
					<div key={section_name} className="row-cols-1">
						{sections[section_name]}
					</div>
				)
			}
			items = items.concat(bit)
			items = items.concat(<hr />)
		}

		let hide = this.props.error_state ? '' : "hidden"
		const warnings = this.props.error_message.map((msg, i) => {
			return (<div key={i} className="alert alert-warning" role="alert">
						{msg}
					</div>)
		})
		return (
			<div className="card">
			<div className="card-body">
				<a href="#" className="btn btn-light float-end" onClick={()=>this.props.reset()}>Reset</a>
				<h5 className="card-title">{this.props.conf.title}</h5>
				<p className="card-text">{this.props.conf.message}</p>
				<form className='row'>
					{items}
				</form>
				<div className={hide}>
				{warnings}
				</div>
				<a href="#" className="btn btn-primary" onClick={()=>this.props.back()}>Back</a>
				<a href="#" className="btn btn-primary float-end" onClick={()=>this.props.next(this.state)}
				style={disable_link(typeof this.state['Blasts']!=='undefined')}>Next</a>
			</div>
			</div>
		);
	}
}