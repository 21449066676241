import React, { useState } from 'react';
import './App.css';
import Card from './Card'
import CytogeneticsCard from './cards/cytogenetics';
import FISHCard from './cards/fish';
import datamodel from './datamodel';
import ResultsCard from './cards/result';
import Results2Card from './cards/result2';
import consensus from './consensus';
import ConsensusCard from './cards/consensus_card';
import FLT3Card from './cards/FLT3Card';

const cyto_api_host = process.env.REACT_APP_CYTO_HOST //'https://amlconsensus.rosalind.kcl.ac.uk/extract/' //`http://${window.location.hostname}:5000/`
const TE_API_HOST = process.env.REACT_APP_TE_HOST // 'https://amlconsensus.rosalind.kcl.ac.uk/run/' //`http://${window.location.hostname}:3001/`
console.log('cyto', cyto_api_host)
console.log('TE', TE_API_HOST)
let defaults = {}
for (const [name, value] of Object.entries(datamodel.variables)) {
  defaults[name] = value.initialValue
}

const fish_variables = Object.keys(datamodel.variables).filter((el) => {return datamodel.variables[el].hasOwnProperty('fish')})



async function cytogenetics_API(karyotype, fish = {}){
  const url = `${cyto_api_host}karyotype/extract`;
  console.log('extracting from', url)
  let data = {'karyotype_string': karyotype, 'fish': fish, 'bool_mode': 'bool', 'version':'ELN2022'}
  let test_name
  if(fish.hasOwnProperty('fish_performed')){
    if(fish.fish_performed === false){
      delete data['fish']
    }
    else {
      for (const vname of fish_variables) {
        test_name = vname + "_tested"
        if (fish[test_name] === false){
          delete data.fish[vname]
        }
        delete data.fish[test_name]
      }
    }
  }
  
  
  console.log("submitted karyotype:", data)
  let res = {}
  let message = []
  let status
  let warnings = []
  try {
    let raw_response = await fetch(url, {method: 'POST', body: JSON.stringify(data), headers: {'Content-Type': 'application/json'}})
    console.log('got response')
    console.log(raw_response)
    if(raw_response.status == 200){
      let body = await raw_response.json()
      console.log(body)
      status = !body.error
      message = body.error_message
      if (body.Warnings)
        warnings = body.Warnings

      res = body.result
      res['Number_of_cytogenetic_changes'] = res['Number of cytogenetic abnormalities']

      res['Trisomy'] = res['Polysomy']
      res['del(11q)'] = res['del11q']
      res['del(12p)'] = res['del12p']
      res['del(13q)'] = res['del13q']
      res['del(5q)'] = res['del5q']
      res['del(7q)'] =res['del7q']

    } else {
      status = false
      message = ["Cytogenetic server error"]
    }
  } catch (error) {
    status = false
    message = ["Cytogenetic server cannot process input"]
    console.log("got error")
    console.log(error)
  }
  let result = {response: res, message: message, status: status, warnings:warnings}  
  return result
}

async function TE_API(data){
  const url = `${TE_API_HOST}api/run`;
  let res = {}
  let status
  let message = []
  console.log('sending to TE', data)
  console.log(Object.keys(data).map(function(k){return k}).join(","))
  console.log(Object.keys(data).map(function(k){return data[k]}).join(","))
  const d = {'model_input': data, 'version': 'EIGHT_TREES_2023'}
  try {
    let raw_response = await fetch(url, {method: 'POST', body: JSON.stringify(d), headers: {'Content-Type': 'application/json'}})
    console.log('got response')
    console.log(raw_response)
    if(raw_response.status == 200){
      let body = await raw_response.json()
      console.log(body)
      // status = !body.error
      // message = body.error_message
      status = true
      res = body
    } else {
      status = false
      message = ["TE server error"]
    }
  } catch (error) {
    status = false
    message = ["TE server cannot process input"]
    console.log("got error")
    console.log(error)
  }
  let result = {response: res, message: message, status: status}  
  return result
}

function prep_for_TE(data, schema){
  let for_te = {}
  let type
  for (const [name, value] of Object.entries(data)) {
    if(schema.hasOwnProperty(name)){
      if(schema[name].hasOwnProperty('type')){
        //have a definite type specified
        type = schema[name].type
        if(type === "bool"){
          for_te[name] = value
        } else if(type === "num"){
          for_te[name] = parseFloat(value)
        } else if(type === "str"){
          for_te[name] = value.toString()
        } else {
          console.log("specified type is unknown")
          for_te[name] = value
        }
      } else {
        for_te[name] = value
      }
    }
  }
  return for_te
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.state = defaults;
    this.state['_TE_VARS'] = datamodel.cards.review.variables

    this.cyto_next = this.cyto_next.bind(this)
    this.render_card = this.render_card.bind(this)
    this.fish_next = this.fish_next.bind(this)
    this.base_next = this.base_next.bind(this)
    this.TE_next = this.TE_next.bind(this)
    this.back = this.back.bind(this);
    this.reset = this.reset.bind(this);
    this.results_next = this.results_next.bind(this);
    this.cyto_preview = this.cyto_preview.bind(this);
  }

  render_card(){
    let active = datamodel.card_sequence[this.state._card_position]

    if(active === "review"){
      let card_vals = {}
      for (const vname of datamodel.cards[active].variables) {
        card_vals[vname] = this.state[vname]
      }
      let error = this.state._fish_error // can add OR here to check for one of several errors to flag in review
      let message = this.state._fish_message
      return <Card key={active} conf={datamodel.cards[active]} all_variables={datamodel.variables} values={card_vals} next={this.TE_next} back={this.back} error_state={error} error_message={message} reset={this.reset}/>
    
    } else if(active === "other_data"){
      let card_vals = {}
      for (const vname of datamodel.cards[active].variables) {
        card_vals[vname] = this.state[vname]
      }
      return <Card key={active} conf={datamodel.cards[active]} all_variables={datamodel.variables} values={card_vals} next={this.base_next} back={this.back} reset={this.reset}/>
      
    } else if(active === 'cytogenetics'){
      return <CytogeneticsCard next={this.cyto_next} cyto_string={this.state.cytogenetics} back={this.back} error_state={this.state._cytogenetics_error} error_message={this.state._cytogenetics_message} reset={this.reset} preview={this.cyto_preview} data={this.state}></CytogeneticsCard>
    } else if(active === 'fish_yn'){
      let fish_vals = {}
      for (const vname of fish_variables) {
        fish_vals[vname] = this.state[vname]
      }
      fish_vals['fish_performed'] = this.state['fish_performed']
      return <FISHCard fish_vars={fish_variables} all_variables={datamodel.variables} next={this.fish_next} values={fish_vals} back={this.back} reset={this.reset}></FISHCard>
    } else if(active === 'results'){
      return <ResultsCard status={this.state._TE_status} back={this.back} reset={this.reset}  error_state={this.state._TE_error} error_message={this.state._TE_message} results_next={this.results_next} data={this.state}></ResultsCard>
    } else if(active === 'flt3'){
      return <FLT3Card flt3={this.state['FLT3-ITD_mut']} flt3_low={this.state['FLT3-ITD_low']} flt3_high={this.state['FLT3-ITD_high']} back={this.back} next={this.base_next} reset={this.reset}/>
    } else if (active === 'results2') { // add a new card
      return <Results2Card status={this.state._TE_status} back={this.back} reset={this.reset}  error_state={this.state._TE_error} error_message={this.state._TE_message} results_next={this.results_next} data={this.state}></Results2Card>
    } else {
      return <Card key={'__notfound__'} conf={{title:"NOT FOUND", message:"There was an internal error", variables:[]}} variables={datamodel.variables} back={this.back} reset={this.reset}/>
    }

  }

  reset(){
    let state = defaults;
    this.setState(state)
  }

  back(){
    let pos = this.state._card_position - 1
    this.setState({"_card_position": pos})
  }

  async cyto_next(cyto_string) {
    let update = await cytogenetics_API(cyto_string)
    console.log('got update from API', update)
    let s = ''
    Object.keys(update.response).forEach((k) => s+=k+',')
    console.log(s)
    let u
    if(update.status){
      u = update.response
      u['cytogenetics'] = cyto_string
      u['_cytogenetics_message'] = update.message
      u['_cytogenetics_error'] = false
      this.base_next(u)
    } else {
      console.log('wait for cytogenetic data to be valid')
      u = {}
      u['cytogenetics'] = cyto_string
      u['_cytogenetics_message'] = update.message
      u['_cytogenetics_error'] = true
      this.setState(u)
    } 
  }

  async cyto_preview(cyto_string) {
    let update = await cytogenetics_API(cyto_string)
    console.log('Preview: got update from API', update)
    let u
    if(update.status){
      u = update.response
      u['cyto_preview'] = true
      u['cytogenetics'] = cyto_string
      u['_cytogenetics_message'] = update.message
      u['_cytogenetics_error'] = false
      u['_warnings'] = update.warnings
      this.setState(u)
    } else {
      console.log('wait for cytogenetic data to be valid')
      u = {}
      u['cyto_preview'] = true
      u['cytogenetics'] = cyto_string
      u['_cytogenetics_message'] = update.message
      u['_cytogenetics_error'] = true
      this.setState(u)
    } 
  }

  //async fish_next(fish_data){
  fish_next(fish_data){
    let fish_error = false
    let message = ''
    let u = {}
    console.log('fish_data:', fish_data)
    if (fish_data['fish_performed']===true) {
      // negative
      if (fish_data['FISH_CBFB-MYH11']===false) {
        if (this.state['inv(16)(p13.1q22)']===true) fish_error = true
        if (this.state['t(16;16)(p13.1;q22)']===true) fish_error = true
        u['inv(16)(p13.1q22)'] = false
        u['t(16;16)(p13.1;q22)'] = false
      }
      if (fish_data['FISH_RUNX1-RUNX1T1']===false) {
        if (this.state['t(8;21)(q22;q22.1)']===true) fish_error = true
        u['t(8;21)(q22;q22.1)'] = false
      }
      if (fish_data['FISH_PML-RARA']===false) {
        if (this.state['t(15;17)(q24.1;q21.2)']===true) fish_error = true
        u['t(15;17)(q24.1;q21.2)'] = false
      }
      if (fish_data['FISH_MLL']===false) {
        if (this.state['t(v;11q23.3)']===true) fish_error = true
        if (this.state['t(11;16)(q23.3;p13.3)']===true) fish_error = true
        if (this.state['t(2;11)(p21;q23.3)']===true) fish_error = true
        if (this.state['t(9;11)(p21.3;q23.3)']===true) fish_error = true
        u['t(v;11q23.3)'] = false
        u['t(11;16)(q23.3;p13.3)'] = false
        u['t(2;11)(p21;q23.3)'] = false
        u['t(9;11)(p21.3;q23.3)'] = false
      }
      if (fish_data['FISH_MECOM']===false) {
        if (this.state['t(3q26.2;v)']===true) fish_error = true
        if (this.state['inv(3)(q21.3q26.2)']===true) fish_error = true
        if (this.state['t(3;3)(q21.3;q26.2)']===true) fish_error = true
        if (this.state['t(3;21)(q26.2;q22.1)']===true) fish_error = true
        u['t(3q26.2;v)'] = false
        u['inv(3)(q21.3q26.2)'] = false
        u['t(3;3)(q21.3;q26.2)'] = false
        u['t(3;21)(q26.2;q22.1)'] = false
      }
      if (fish_data['FISH_Monosomy5']===false) {
        if (this.state['Monosomy5']===true) fish_error = true
        u['Monosomy5'] = false
      }
      if (fish_data['FISH_Monosomy7']===false) {
        if (this.state['Monosomy7']===true) fish_error = true
        u['Monosomy7'] = false
      }
      //positive
      if (fish_data['FISH_CBFB-MYH11']===true) {
        if (this.state['inv(16)(p13.1q22)']===false) fish_error = true
        if (this.state['t(16;16)(p13.1;q22)']===false) fish_error = true
        u['inv(16)(p13.1q22)'] = true
        u['t(16;16)(p13.1;q22)'] = true
        message = 'Note, based on the FISH detection of CBFB-MYH11, the app has selected both inv(16) and t(16;16) as present, but has only counted this as one abnormality'
      }
      if (fish_data['FISH_RUNX1-RUNX1T1']===true) {
        if (this.state['t(8;21)(q22;q22.1)']===false) fish_error = true
        u['t(8;21)(q22;q22.1)'] = true
      }
      if (fish_data['FISH_PML-RARA']===true) {
        if (this.state['t(15;17)(q24.1;q21.2)']===false) fish_error = true
        u['t(15;17)(q24.1;q21.2)'] = true
      }
      if (fish_data['FISH_MLL']===true) {
        if (this.state['t(v;11q23.3)']===false) fish_error = true
        u['t(v;11q23.3)'] = true
      }
      if (fish_data['FISH_MECOM']===true) {
        if (this.state['t(3q26.2;v)']===false) fish_error = true
        u['t(3q26.2;v)'] = true
      }
      if (fish_data['FISH_Monosomy5']===true) {
        if (this.state['Monosomy5']===false) fish_error = true
        u['Monosomy5'] = true
      }
      if (fish_data['FISH_Monosomy7']===true) {
        if (this.state['Monosomy7']===false) fish_error = true
        u['Monosomy7'] = true
      }
    }
    if (fish_error) {
      u['_fish_error'] = 'FISH discrepancy'
      u['_fish_message'] = ['FISH results are not consistent with karyotyping. FISH results have been used in cases of disordance.']
      if (message !== '') u['_fish_message'] = [...u['_fish_message'], message]
    }
    this.base_next(u)
    // let update = await cytogenetics_API(this.state.cytogenetics, fish_data)
    // console.log('got update from API', update)
    // if(update.status){
    //   let u = update.response
    //   //can only get to this point in the app if the cytogenetic string is valid
    //   //but the FISH data could show discordant results
    //   u['_fish_error'] = u['FISH discrepancy']
    //   u['_fish_message'] = ['']
    //   if(u._fish_error){
    //     u._fish_message = ['FISH results are not consistent with karyotyping. FISH results have been used in cases of disordance.']
    //   } 
    //   this.base_next(u)
    // } else {
    //   console.log('FISH update got an error')
    // } 
  }

  async TE_next(data){
    const te_data = prep_for_TE(data, datamodel.variables)
    let update = await TE_API(te_data)
    console.log('TE:', update)

    //TODO set whatever state for TE

    if(update.status){
      data['_TE_status'] = update.response.state
      data['_TE_error'] = false
      data['_TE_message'] = ['']
    } else {
      data['_TE_status'] = {}
      data['_TE_error'] = true
      data['_TE_message'] = update.message
    }
    this.base_next(data)
    
  }

  base_next(data){
    let upd = Object.assign({}, data)
    upd['_card_position'] = this.state['_card_position'] + 1
    console.log("updating state with ", upd)
    this.setState(upd)
  }

  results_next(data){
    console.log('results_next:', data)
    let u = {}
    u['ack'] = data.ack
    u['age'] = data.age
    this.base_next(u)    
  }

  render() {
    return (
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand mb-0 h1 display-3" href="#">AML Companion App</a>
          </div>
        </nav>
    
    <div className="container-fluid">
      <div className='row'>
        <div className='col mb-3'>
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="true">Study Eligibility</button>
            <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Study Consensus</button>
            <button class="nav-link" id="nav-about-tab" data-bs-toggle="tab" data-bs-target="#nav-about" type="button" role="tab" aria-controls="nav-about" aria-selected="false">About</button>
            <button class="nav-link" id="nav-disclaimer-tab" data-bs-toggle="tab" data-bs-target="#nav-disclaimer" type="button" role="tab" aria-controls="nav-disclaimer" aria-selected="false">Disclaimer</button>
            <button class="nav-link" id="nav-privacy-tab" data-bs-toggle="tab" data-bs-target="#nav-privacy" type="button" role="tab" aria-controls="nav-privacy" aria-selected="false">Privacy</button>
            <button class="nav-link" id="nav-accessibility-tab" data-bs-toggle="tab" data-bs-target="#nav-accessibility" type="button" role="tab" aria-controls="nav-accessibility" aria-selected="false">Accessibility</button>
          </div>
        </nav>
        <div class="tab-content mt-3" id="nav-tabContent">



          <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <div className="row">
        <div className="col">
          {this.render_card()}
          <div class="alert alert-light" role="alert">
            Step {this.state._card_position + 1} of {datamodel.card_sequence_length}
          </div>
        </div>
      </div>
          </div>


          <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div className='row'>
            <div className='col'>
              <ConsensusCard />
            </div>
          </div>
          </div>


          <div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
          <div className='row'>
            <div className='col'>
              <h3>About the app</h3>
              <p>The content of this web application is an accompaniment to the research paper “A Novel algorithmic approach to generate consensus treatment guidelines in adult Acute Myeloid Leukaemia” by Coats et al. (2021), which is published in the British Journal of Haematology (<a href="https://doi.org/10.1111/bjh.18013">https://doi.org/10.1111/bjh.18013</a>) and is open access. 
                </p>

                <p><b>NB. The treatment recommendations have been updated in 2023 to reflect the new ELN classification (ELN 2022) and also integrating <i>FLT3-TKD</i> and <i>TP53</i> mutation status (the latter in the presence of a complex karyotype only).</b></p>

              <p>The intended use of the app is for new suspected AML cases and is to provide assistance to clinicians in up to 5 areas:
              <ol type="I">
              <li>interpreting cytogenetic reports</li>
              <li>assigning diagnoses according to WHO (2016 and 2022) and ICC classifications</li>
              <li>assigning prognosis according to ELN 2022</li>
              <li>eligibility for specific therapies (gemtuzumab, midostaurin and CPX-351) on the NHS for cases confirmed as AML</li>
              <li>for appropriate confirmed cases of AML<sup>*</sup> to provide a digital link to the consensus treatment guideline referenced in the paper</li>
              </ol>
              </p>
              <p><sup>*</sup>The consensus recommendations <b>do not</b> refer to AML arising from prior MPN, MDS/AML overlap, APL, biphenotypic leukaemia, myeloid sarcoma or previously treated AML or non-intensive treatment. These treatment recommendations are based on having results of NPM1 and flt 3 mutation status. If a treatment decision is to be made before full karyotype is back then a normal karyotype should be assumed (unless FISH results demonstrate a relevant abnormality). These treatment recommendations do not integrate results of additional mutations e.g. ASXL1, U2AF1 etc. The exception to this is if TP53 mutation status is detected in the context of a complex karyotype as there are specific treatment recommendations for this scenario.</p>
                
              <h3>Study Eligibility</h3>
              <p>All 5 functions can be accessed by clicking on the Study Eligibility tab and is the recommended tab to use. <a href={`${process.env.PUBLIC_URL}/download/AML_companion_app_study eligibility_instructions.docx`}>Please download instructions for use of this tab.</a></p>

              <h3>Study Consensus</h3>
              <p>Where only the consensus treatment (point v) is required this can be accessed by clicking on the Study Consensus tab. NB if only accessing the consensus treatment then the user must manually input the eligibility for different treatments, age, FLT3 status and ELN risk. <a href={`${process.env.PUBLIC_URL}/download/AML_companion_app_consensus_instructions.docx`}>Please download instructions for use of the consensus treatment tab only.</a></p>


              <p>This research project has been approved by the Health Research Authority, REC number 21/PR/1238. The AML17 trial is registered with the ISRCTN registry, number ISRCTN55675535, REC 08/MRE09/29.</p>

              <h3>Data</h3>
              <p>This procedure sends data to services hosted on King's College London's CREATE Cloud infrastructure. The transmitted data is not stored and is not processed for any other purpose.</p>

              <h3>Funding</h3>
              <p>Financial support was provided by an Independent Medical Education Grant from Pfizer Limited. Pfizer Limited were not involved in the study conception, study design or generation of the consensus recommendations.</p>

              <h3>Citation</h3>
              <p>“A Novel algorithmic approach to generate consensus treatment guidelines in adult Acute Myeloid Leukaemia” by Coats et al. (2021) British Journal of Haematology (<a href="https://doi.org/10.1111/bjh.18013">https://doi.org/10.1111/bjh.18013</a>) and is open access. </p>
            </div>
          </div>
          </div>

          <div class="tab-pane fade" id="nav-disclaimer" role="tabpanel" aria-labelledby="nav-disclaimer-tab">
          <div className='row'>
            <div className='col'>
              <h3>Disclaimer</h3>
              
              <p>We reserve the right not to be responsible for the topicality, correctness, completeness or quality of the information provided. </p>
  
              <p>Liability claims regarding damage caused by the use of any information provided, including any kind of information which is incomplete or incorrect, will therefore be rejected.</p>

              <p>The material embodied in this software is provided to you "as-is" and without warranty of any kind, express, implied or otherwise, including without limitation, any warranty of fitness for a particular purpose.</p>
  
              <p>Although this represents the medical opinions of clinicians who are members of the NCRI AML working group, this guideline does not represent the views or opinions of the NCRI working group. </p>
  
              <p>All offers are not-binding and without obligation. Parts of the pages or the complete publication including all offers and information might be extended, changed or partly or completely deleted by the author without separate announcement.</p>
  
              <p>All medical content on this site has been developed, reviewed, and monitored independently by physicians specializing in the particular field. </p>
  
              <p>All research and clinical material published on this website is for informational purposes only. Readers are encouraged to confirm the information contained herein with other sources.</p>
  
              <p>Patients and consumers should review the information carefully with their professional health care provider. The information is not intended to replace medical advice offered by physicians. We will not be liable for any direct, indirect, consequential, special, exemplary, or other damages arising therefrom.</p>
  
            </div>
          </div>
          </div>

          <div class="tab-pane fade" id="nav-privacy" role="tabpanel" aria-labelledby="nav-privacy-tab">
          <div className='row'>
            <div className='col'>
              <h3>Privacy</h3>
              <p>We do not store any information entered into the tool.</p>
              <p>Use of the "Study Eligibility" section sends entered data to services hosted on King's College Londons Rosalind HPC infrastructure. The transmitted data is not stored and is not processed for any other purpose.</p>
              <p>We collect anonymised usage statistics to monitor usage and availability. These statistics cannot be linked to any individual or to data submitted to the tool.</p>

            </div>
          </div>
          </div>

          <div class="tab-pane fade" id="nav-accessibility" role="tabpanel" aria-labelledby="nav-accessibility-tab">
          <div className='row'>
            <div className='col'>
              <h3>Accessibility Statement</h3>
              <h4>Version 1.0</h4>
<p>
Last updated: 05/01/2023
</p>
<p>At Haem Support Ltd we are working towards WCAG 2.1 AA level requirements and expects to be fully compliant later 2023. We always follow the 6 key principles under the human-centred design process conforming to the ISO 9241-210 standards when creating new technologies which include – discovery, then alpha and beta phases, before a full go-live is enabled.</p>
<p>During the build of both the web platform a detailed analysis of our user demographics was clearly defined, and this process continues to be an integral part of our product development. In addition, co-design research informed the early stages of our work. Ongoing user co-design and real-world evaluation and feedback inform our work to support users’ evolving needs.</p>
<p>All new features and resources for our web platform undergo a rigorous co-design and approval cycle where new resources and products are tested with users. Research conducted includes user testing, remote testing, user questionnaires and Penetration Testing.</p>
<p>Throughout the evaluation of early versions and pre-release versions, changes were made to the platform to reflect user feedback and an assessment for usability and accessibility was undertaken. We also have a platform bug fix and change log to support ongoing feedback and are responsive to the feedback on requirements from the programme team.</p>


            </div>
          </div>
          </div>


          


        </div> 
        </div>
      </div>
    
      
      
    </div>

    <div className='row mt-1'>
      <div className='col'>
        <p className="text-center">This app displays the results of a research study. It is for research use only.</p>
        <p className="text-center">This app is a decision support tool, the included calculations should not be used alone and must not be a substitute for clinical judgement and review. All results should be manually reviewed by an expert. See Disclaimer for more details.</p>
      </div>
    </div>

    <div className='row mt-1'>
      <div className='col'>
      <p className="text-center">&#169; Copyright 2021 Dan Bean</p>
      </div>
    </div>
    </div>
    )
  }
}

export default App;
