import React, { useState } from 'react';
import datamodel from '../datamodel'

export default function CytogeneticsCard(props) {
	const [cyto_str, set_cyto_str] = useState(props.cyto_string)
	let hide = "hidden"
	let invalid = "form-control"
	if(props.error_state){
		hide = ""
		invalid = "form-control is-invalid"
	}
	let preview_hide = "hidden"
	if(props.data.cyto_preview) {
		preview_hide = ""
	}
	const warnings = props.error_message.map((msg, i) => {
		return (<div key={i} className="alert alert-warning" role="alert">
					{msg}
				</div>)
	})
	const preview = () => {
		//console.log('preview', props.data)
		//console.log('datamodel', datamodel.variables)
		let s = ''
		Object.keys(props.data).forEach(k => {if (props.data[k]===true && datamodel.variables[k] && datamodel.variables[k].TE) s += k + ', '});
		if (s.length > 0) s = s.slice(0, -2);
		let w = ''
		if (Array.isArray(props.data['_warnings']))
			props.data['_warnings'].forEach(k => w += k + ', ')
		if (w.length > 0) w = w.slice(0, -2);
		return (
			<div>
				<li>Number of cytogenetic abnormalities: {props.data['Number of cytogenetic abnormalities']}</li>
				<li>Polysomy: {props.data['Polysomy']}</li>
				<li>Monosomy: {props.data['Monosomy']}</li>
				<li>Structural abnormality count: {props.data['Structural']}</li>
				<li>Other abrnomalities detected from pre-set list: {s}</li>
				<li>Warnings: {w}</li>
			</div>
		)
	}
	return (
		<div className="card">
		<div className="card-body">
		  <a href="#" className="btn btn-light float-end" onClick={()=>props.reset()}>Reset</a>
		  <h5 className="card-title">Karyotyping</h5>
		  <form>
			<div className="mb-3">
				<label htmlFor="exampleInputEmail1" className="form-label">Cytogenetic string</label>
				<div className="input-group">
				<input type="string" className={invalid} id="exampleInputEmail1" aria-describedby="emailHelp" value={cyto_str} onChange={(e) => set_cyto_str(e.target.value)} placeholder="e.g. 46,XX[20]"/>
				<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={()=>props.next("46,XY[1]")}>Normal</button>
				</div>
				<div id="emailHelp" className="form-text">Use standard cytogenetic nomenclature e.g. 46,XX[20] or 46,XX[7]/45,XY,-7[3]. We recommend copying and pasting the cytogenetic string into the input box.</div>
			</div>
			<div className={preview_hide}>
				{preview()}
			</div>
			<br/>
			<div className={hide}>
			{warnings}
			</div>
			</form>
			<a href="#" className="btn btn-primary" onClick={()=>props.back()}>Back</a>
			<a href="#" className="btn btn-primary" style={{marginLeft: '20px'}} onClick={()=>props.preview(cyto_str)}>Preview</a>
		  <a href="#" className="btn btn-primary float-end" onClick={()=>props.next(cyto_str)}>Next</a>
		</div>
	  </div>
	);
  }
