import React from 'react';

export default class FISHCard extends React.Component {
	constructor(props) {
	  super(props);
	  this.state = this.props.values
	  this.update_data = this.update_data.bind(this)
	  this.update_data2 = this.update_data2.bind(this)
	}
	
	update_data(e) {
		let k = e.name
		let v = e.checked
		let update = {}
		update[k] = v
		if(k === "fish_performed" && v === false){
			for (const vname of this.props.fish_vars) {
				update[vname] = false
				update[vname+"_tested"] = false
			}
		}
		if (k.search('_tested')!=-1 && v == false) {
			update[k.slice(0,k.search('_tested'))] = false
			update[k] = false
		}
		this.setState(update)
	}

	update_data2(e) {
		let k = e.name
		let v = !e.checked
		let update = {}
		update[k] = v
		this.setState(update)
	}
  
	render() {		
		const other_inputs = []
		let el_id, el_id2, el_label, test_id, test_label, test_name
		let hide = this.state.fish_performed ? '' : "hidden"
		for (const vname of this.props.fish_vars) {
			if(vname.includes('_tested')){
				continue
			}
			el_id = `checkbox_${vname}`
			el_id2 = `checkbox_${vname}2`
			el_label = this.props.all_variables[vname].label
			test_name = vname + "_tested" 
			test_id = `checkbox_${test_name}`
			test_label = this.props.all_variables[test_name].label
			other_inputs.push(
			<div key={vname}>
				<div class="mb-3 form-check">
					<input type="checkbox" className="form-check-input" id={test_id} name={test_name} checked={this.state[test_name]} onChange={(e)=>this.update_data(e.target)}/>
					<label className="form-check-label" htmlFor={test_id}><b>{test_label}</b></label>
				</div>
				<div class="mb-3 form-check">
					<input type="checkbox" className="form-check-input" id={el_id} name={vname} checked={this.state[test_name] && this.state[vname]} onChange={(e)=>this.update_data(e.target)} disabled={!this.state[test_name]}/>
					<label className="form-check-label" htmlFor={el_id}>{el_label} detected</label>
				</div>
				<div class="mb-3 form-check">
					<input type="checkbox" className="form-check-input" id={el_id2} name={vname} checked={this.state[test_name] && !this.state[vname]} onChange={(e)=>this.update_data2(e.target)} disabled={!this.state[test_name]}/>
					<label className="form-check-label" htmlFor={el_id2}>{el_label} absent</label>
				</div>
				<br/>
			</div>
			)
		}
	  return (
		<div className="card">
		<div className="card-body">
		  <a href="#" className="btn btn-light float-end" onClick={()=>this.props.reset()}>Reset</a>
		  <h5 className="card-title">FISH data</h5>
		  <p className="card-text">Additional genetic data from FISH</p>
		  <form>
			<div class="mb-3 form-check">
				<input type="checkbox" className="form-check-input" id="exampleCheck1" name='fish_performed' checked={this.state.fish_performed} onChange={(e)=>this.update_data(e.target)}/>
				<label className="form-check-label" htmlFor="exampleCheck1">{this.props.all_variables['fish_performed'].label}</label>
			</div>
			<div className={hide}>
			<p className="card-text">Select the abnormalities that were detected by FISH.</p>

			{other_inputs}
			</div>
			</form>
			<a href="#" className="btn btn-primary" onClick={()=>this.props.back()}>Back</a>
		  <a href="#" className="btn btn-primary float-end" onClick={()=>this.props.next(this.state)}>Next</a>
		</div>
	  </div>
	  )
	}
  }