import React from 'react';

export default class FLT3Card extends React.Component {
	constructor(props) {
	super(props);
	this.state = {}
	this.state['flt3'] = this.props.flt3
	let high_low
	if(this.props.flt3_low && !this.props.flt3_high){
		high_low = 'low'
	} else if(!this.props.flt3_low && this.props.flt3_high){
		high_low = 'high'
	} else {
		high_low = ''
	}
	this.state['high_low'] = high_low

	this.set_flt3_state = this.set_flt3_state.bind(this)
	this.update = this.update.bind(this)
	}

	set_flt3_state = function(v){
		let u = {
			flt3: v,
			high_low: "",

		}
		if(v){
			u.high_low = 'low' // default to low so there has to be a value
		}
		this.setState(u)
	}

	update = function(){
		let u = {'FLT3-ITD_mut': this.state.flt3}
		u['FLT3-ITD_low'] = this.state.high_low === 'low'
		u['FLT3-ITD_high'] = this.state.high_low === 'high'
		this.props.next(u)
	}

	render(){
		return (
			<div className="card">
			<div className="card-body">
			<h5 className="card-title">FLT3-ITD</h5>
			<form>
				<div className="mb-3">
					<div class="form-check">
					<input class="form-check-input" type="checkbox" checked={this.state.flt3} id="flexCheckDefault" onChange={(e)=>this.set_flt3_state(e.target.checked)}/>
					<label class="form-check-label" htmlFor="flexCheckDefault">
						FLT3-ITD mutation
					</label>
					</div>
				</div>

				<div class="form-check">
				<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={this.state.high_low === "low"} disabled={!this.state.flt3} onChange={(e)=>this.setState({high_low:e.target.value})} value='low'/>
				<label class="form-check-label" htmlFor="flexRadioDefault2">
					Low
				</label>
				</div>

				<div class="form-check">
				<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.high_low === "high"} disabled={!this.state.flt3} onChange={(e)=>this.setState({high_low:e.target.value})} value='high'/>
				<label class="form-check-label" htmlFor="flexRadioDefault1">
					High
				</label>
				</div>
				

				</form>
				<a href="#" className="btn btn-primary" onClick={()=>this.props.back()}>Back</a>
			<a href="#" className="btn btn-primary float-end" onClick={()=>this.update()}>Next</a>
			</div>
		</div>
		)
	}
}
